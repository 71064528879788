import { CompanyOptionsContent } from 'src/@types/company';
import { SitePagination, StructureTypeContent } from 'src/@types/site';

export enum SiteSearchQueryActionType {
  SET_QUICK_SEARCH_LOADING = 'SiteSearchQuery@SET_QUICK_SEARCH_LOADING',
  SET_QUICK_SEARCH_QUERY = 'SiteSearchQuery@SET_QUICK_SEARCH_QUERY',
  SET_QUICK_SEARCH_QUERY_RESULT = 'SiteSearchQuery@SET_QUICK_SEARCH_QUERY_RESULT',
  SET_QUICK_SEARCH_QUERY_CLEAR = 'SiteSearchQuery@SET_QUICK_SEARCH_QUERY_CLEAR',
  SET_SEARCH_LOADING = 'SiteSearchQuery@SET_SEARCH_LOADING',
  SET_SEARCH_QUERY = 'SiteSearchQuery@SET_SEARCH_QUERY',
  SET_SEARCH_QUERY_RESULT = 'SiteSearchQuery@SET_SEARCH_QUERY_RESULT',
  SET_SEARCH_QUERY_CLEAR = 'SiteSearchQuery@SET_SEARCH_QUERY_CLEAR',

  GET_SEARCH_QUERY_SELECT_OPTIONS = 'SiteSearchQuery@GET_SEARCH_QUERY_SELECT_OPTIONS',
  SET_SEARCH_QUERY_SELECT_OPTIONS = 'SiteSearchQuery@SET_SEARCH_QUERY_SELECT_OPTIONS',
  SET_SELECT_OPTIONS_LOADING = 'SiteSearchQuery@SET_SELECT_OPTIONS_LOADING',

  CLEAR = 'SiteSearchQuery@CLEAR',
}

export type SiteSearchQueryState = {
  isQuickSearchLoading: boolean;
  quickSearchQuery: any;
  quickSearchQueryResult?: SitePagination;
  isSearchLoading: boolean;
  searchQuery: any;
  searchQueryResult?: SitePagination;

  // select options.
  isSelectOptionsLoading: boolean;
  structureTypesSelectOptions?: StructureTypeContent;
  towerOwnersSelectOptions?: CompanyOptionsContent;
  vendorsSelectOptions?: CompanyOptionsContent;
};

export type SiteSearchQueryPayload = {
  isQuickSearchLoading?: boolean;
  quickSearchQuery?: any;
  quickSearchQueryResult?: SitePagination;
  isSearchLoading?: boolean;
  searchQuery?: any;
  searchQueryResult?: SitePagination;
  searchQueryAllResults?: SitePagination;

  // select options.
  isSelectOptionsLoading?: boolean;
  structureTypesSelectOptions?: StructureTypeContent;
  towerOwnersSelectOptions?: CompanyOptionsContent;
  vendorsSelectOptions?: CompanyOptionsContent;
};

export type SiteSearchQueryAction = {
  payload?: SiteSearchQueryPayload;
  type: SiteSearchQueryActionType;
};

export type SiteSearchQueryReducer = (
  state: SiteSearchQueryState,
  payload?: SiteSearchQueryPayload
) => SiteSearchQueryState;
