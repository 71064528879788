// api
import api from './api';
import { CompanyPagination, CompanyContent, CompanyRequestObj, CompanyOptionsContent, OwnershipTypeContent, CompanyUserContent, CompanyUserPagination, CompanyUserRequestBodyObj, RevokedCompanyUserRequestObj } from 'src/@types/company';

import {
  CompanyResponseSchema,
  CompanyCursorPaginatorResponseSchema,
  CompanyOptionsResponseSchema,
  OwnershipTypeOptionsResponseSchema,
  CompanyUserCursorPaginatorResponseSchema,
  CompanyUserResponseSchema,
} from './types';


export const fetchAllCompanies = async (
  limit: number,
  cursor?: string,
  search?: string,
  search_field?: string,
  order_by_direction?: string,
  order_by?: string,
  is_tower_owner?: boolean,
  is_vendor?: boolean
): Promise<CompanyPagination> => {
  try {
    const response: CompanyCursorPaginatorResponseSchema = await api.fetchAllCompanies(
      limit,
      cursor,
      search,
      search_field,
      order_by_direction,
      order_by,
      is_tower_owner,
      is_vendor
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const fetchCompanyContent = async (
  company_id: string
): Promise<CompanyContent> => {
  try {
    const response: CompanyResponseSchema = await api.fetchCompanyContent(company_id);
    return response;
  } catch (e) {
    throw e;
  }
};

export const postCompany = async (
  company: CompanyRequestObj
): Promise<CompanyContent> => {
  try {
    const response: CompanyResponseSchema = await api.postCompany(company);
    return response;
  } catch (e) {
    throw e;
  }
};

export const patchCompany = async (
  company_id: string,
  company: CompanyRequestObj
): Promise<CompanyContent> => {
  try {
    const response: CompanyResponseSchema = await api.patchCompany(company_id, company);
    return response;
  } catch (e) {
    throw e;
  }
};

export const deleteCompany = async (
  company_id: string,
): Promise<any> => {
  try {
    const response = await api.deleteCompany(company_id);
    return response;
  } catch (e) {
    throw e;
  }
};

export const deleteCompanyAssetGroupPermission = async (
  company_id: string,
): Promise<any> => {
  try {
    const response = await api.deleteCompanyAssetGroupPermission(company_id);
    return response;
  } catch (e) {
    throw e;
  }
};

export const deleteCompanyCustomerSuccess = async (
  company_id: string,
): Promise<any> => {
  try {
    const response = await api.deleteCompanyCustomerSuccess(company_id);
    return response;
  } catch (e) {
    throw e;
  }
};

export const deleteCompanyGuestAccess = async (
  company_id: string,
): Promise<any> => {
  try {
    const response = await api.deleteCompanyGuestAccess(company_id);
    return response;
  } catch (e) {
    throw e;
  }
};



export const fetchCompanyOptions = async (
  search?: string,
  is_vendor?: boolean,
  is_tower_owner?: boolean
): Promise<CompanyOptionsContent> => {
  try {
    const response: CompanyOptionsResponseSchema = await api.fetchCompanyOptions(search, is_vendor, is_tower_owner);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchOwnershipTypeOptions = async (): Promise<OwnershipTypeContent> => {
  try {
    const response: OwnershipTypeOptionsResponseSchema = await api.fetchOwnershipTypeOptions();
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchAllCompanyUsers = async (
  limit: number,
  cursor?: string,
  search?: string,
  search_field?: string,
  order_by_direction?: string,
  order_by?: string,
  company_id?: string,
  only_external?: boolean,
): Promise<CompanyUserPagination> => {
  try {
    const response: CompanyUserCursorPaginatorResponseSchema = await api.fetchAllCompanyUsers(
      limit,
      cursor,
      search,
      search_field,
      order_by_direction,
      order_by,
      company_id,
      only_external
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const postCompanyUser = async (
  company_id: string,
  company_acesses: CompanyUserRequestBodyObj
): Promise<CompanyUserContent> => {
  try {
    const response: CompanyUserResponseSchema = await api.postCompanyUser(company_id, company_acesses);
    return response;
  } catch (e) {
    throw e;
  }
};

export const deleteCompanyUser = async (
  company_id: string,
  user_ids: RevokedCompanyUserRequestObj
): Promise<CompanyUserContent> => {
  try {
    const response: CompanyUserResponseSchema = await api.deleteCompanyUser(company_id, user_ids);
    return response;
  } catch (e) {
    throw e;
  }
};