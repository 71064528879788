export type OwnershipOptions = {
  id?: string;
  name?: string;
};

export type OwnershipTypeContent = {
  ownership_types?: OwnershipOptions[];
};

export type CompanyOptions = {
  id?: string;
  name?: string;
};


export type CompanyOptionsContent = {
  companies?: CompanyOptions[];
};

export type Company = {
  id?: string;
  name?: string;
  short_name?: string;
  abbreviation?: string;
  website?: string;
  accounts_payable_email?: string;
  bill_to_address?: string;
  accounts_receivable_email?: string;
  bill_from_address?: string;
  users_count?: number;
  is_vendor?: boolean;
  is_tower_owner?: boolean;
  date_created?: Date;
};

export type CompanyContent = {
  company?: Company;
};

export type CompanyPagination = {
  next_page_cursor?: string;
  previous_page_cursor?: string;
  total_count?: number;
  page_size?: number;
  current_page?: number;
  last_page?: number;
  companies?: Company[];
};

export type CompanyBody = {
  name?: string;
  short_name?: string;
  abbreviation?: string;
  website?: string;
  accounts_payable_email?: string;
  bill_to_address?: string;
  accounts_receivable_email?: string;
  bill_from_address?: string;
  is_vendor?: boolean;
  is_tower_owner?: boolean;
};

export type CompanyRequestObj = {
  company?: CompanyBody;
};

export enum COMPANY_FORM_VARIANT {
  EDIT = 'Edit',
  CREATE = 'Create',
}

export enum COMPANY_COLUMNS {
  NAME = 'name',
  SHORT_NAME = 'short_name',
  ABBREVIATION = 'abbreviation',
  USERS = 'users_count',
  ACTIONS = 'Actions',
}


//Manage Access

export type CompanyUser = {
  id?: string;
  company_id?: string;
  company_name?: string;
  user_id?: string;
  user_full_name?: string;
  user_email?: string;
  user_company_id?: string;
  user_company_name?: string;
}

export type CompanyUserContent = {
  message?: string;
};

export type CompanyUserPagination = {
  next_page_cursor?: string;
  previous_page_cursor?: string;
  total_count?: number;
  page_size?: number;
  current_page?: number;
  last_page?: number;
  company_accesses?: CompanyUser[];
};

export type CompanyUserRequestObj = {
  user_id?: string;
  role?: string;
};

export type CompanyUserRequestBodyObj = {
  company_accesses?: CompanyUserRequestObj[];
};

export type RevokedCompanyUserRequestObj = {
  user_ids?: string[];
};