import {
  CompanyAction,
  CompanyActionType,
  CompanyReducer,
  CompanyPayload,
  CompanyState,
} from './types';

export const initialState: CompanyState = {
  limit: 15,
  cursor: undefined,
  order_by: 'users_count',
  order_by_direction: 'desc',
  search_field: undefined,
  search: undefined,
  is_tower_owner: undefined,
  is_vendor: undefined,
  company_pagination: undefined,
  company_content: undefined,
  error: undefined,
  is_loading: false,
  company_content_loading: false,
  company_create_loading: false,
  company_update_loading: false,
  company_request_obj: undefined,
  is_company_create_success: false,
  is_company_update_success: false,

  // Companies options.
  company_options: undefined,
  is_company_options_loading: false,

  // ownership types.
  ownership_types: undefined,
  is_ownership_types_loading: false,

  is_company_delete_success: false,
};

const setCompanies: CompanyReducer = (
  state: CompanyState,
  payload?: CompanyPayload
) => ({
  ...state,
  company_pagination: (payload && payload.company_pagination) ?? state.company_pagination,
});

const setCompanySelectionId: CompanyReducer = (state: CompanyState, payload?: CompanyPayload) => ({
  ...state,
  company_selection_id: payload ? payload.company_selection_id : undefined
});

const setError: CompanyReducer = (state: CompanyState, payload?: CompanyPayload) => ({
  ...state,
  error: payload ? payload.error : undefined,
});

const setLoading: CompanyReducer = (state: CompanyState, payload?: CompanyPayload) => ({
  ...state,
  is_loading: !!payload?.is_loading,
});

const setCompanyContentLoading: CompanyReducer = (state: CompanyState, payload?: CompanyPayload) => ({
  ...state,
  company_content_loading: (payload && payload.company_content_loading !== undefined) ? payload.company_content_loading : state.company_content_loading,
});

const setCompanyCreateLoading: CompanyReducer = (state: CompanyState, payload?: CompanyPayload) => ({
  ...state,
  company_create_loading: (payload && payload.company_create_loading !== undefined) ? payload.company_create_loading : state.company_create_loading,
});

const setCompanyUpdateLoading: CompanyReducer = (state: CompanyState, payload?: CompanyPayload) => ({
  ...state,
  company_update_loading: (payload && payload.company_update_loading !== undefined) ? payload.company_update_loading : state.company_update_loading,
});

const setLimit: CompanyReducer = (
  state: CompanyState,
  payload?: CompanyPayload
) => ({
  ...state,
  limit: (payload && payload.limit) ?? state.limit,
});

const setCursor: CompanyReducer = (
  state: CompanyState,
  payload?: CompanyPayload
) => ({
  ...state,
  cursor: (payload && payload.cursor) ?? state.cursor,
});

const setOrderBy: CompanyReducer = (
  state: CompanyState,
  payload?: CompanyPayload
) => ({
  ...state,
  order_by: (payload && payload.order_by) ?? state.order_by,
});

const setOrderByDirection: CompanyReducer = (
  state: CompanyState,
  payload?: CompanyPayload
) => ({
  ...state,
  order_by_direction: (payload && payload.order_by_direction) ?? state.order_by_direction,
});

const setSearch: CompanyReducer = (
  state: CompanyState,
  payload?: CompanyPayload
) => ({
  ...state,
  search: (payload && payload.search) ?? state.search,
});

const setSearchField: CompanyReducer = (
  state: CompanyState,
  payload?: CompanyPayload
) => ({
  ...state,
  search_field: (payload && payload.search_field) ?? state.search_field,
});

const setIsTowerOwner: CompanyReducer = (
  state: CompanyState,
  payload?: CompanyPayload
) => ({
  ...state,
  is_tower_owner: (payload && payload.is_tower_owner) ?? state.is_tower_owner,
});

const setIsVendor: CompanyReducer = (
  state: CompanyState,
  payload?: CompanyPayload
) => ({
  ...state,
  is_vendor: (payload && payload.is_vendor) ?? state.is_vendor,
});


const setCompanyContent: CompanyReducer = (
  state: CompanyState,
  payload?: CompanyPayload
) => ({
  ...state,
  company_content: (payload && payload.company_content) ?? state.company_content,
});

const setCompanyCreateSuccess: CompanyReducer = (state: CompanyState, payload?: CompanyPayload) => ({
  ...state,
  is_company_create_success: (payload && payload.is_company_create_success) ?? state.is_company_create_success
});

const setCompanyUpdateSuccess: CompanyReducer = (state: CompanyState, payload?: CompanyPayload) => ({
  ...state,
  is_company_update_success: (payload && payload.is_company_update_success) ?? state.is_company_update_success
});

const setCompanyDeleteSuccess: CompanyReducer = (state: CompanyState, payload?: CompanyPayload) => ({
  ...state,
  is_company_delete_success: payload?.is_company_delete_success,
});

const setCompanyDeleteLoading: CompanyReducer = (state: CompanyState, payload?: CompanyPayload) => ({
  ...state,
  company_delete_loading: (payload && payload.is_company_delete_loading !== undefined) ? payload.is_company_delete_loading : state.is_company_delete_loading,
});

// companies options.
const setCompanyOptions: CompanyReducer = (
  state: CompanyState,
  payload?: CompanyPayload
) => ({
  ...state,
  company_options: (payload && payload.company_options) ?? state.company_options,
});

const setCompanyOptionsLoading: CompanyReducer = (
  state: CompanyState,
  payload?: CompanyPayload
) => ({
  ...state,
  is_company_options_loading:
    (payload && payload.is_company_options_loading) ?? state.is_company_options_loading,
});

// ownership types.
const setOwnershipTypes: CompanyReducer = (
  state: CompanyState,
  payload?: CompanyPayload
) => ({
  ...state,
  ownership_types: (payload && payload.ownership_types) ?? state.ownership_types,
});

const setOwnershipTypesLoading: CompanyReducer = (
  state: CompanyState,
  payload?: CompanyPayload
) => ({
  ...state,
  is_ownership_types_loading:
    (payload && payload.is_ownership_types_loading) ?? state.is_ownership_types_loading,
});

const setCompanyRequestObj: CompanyReducer = (
  state: CompanyState,
  payload?: CompanyPayload
) => ({
  ...state,
  company_request_obj: (payload && payload.company_request_obj) ?? state.company_request_obj,
});

const clearState: CompanyReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<CompanyActionType, CompanyReducer>([
  [CompanyActionType.SET_COMPANIES, setCompanies],
  [CompanyActionType.SET_LIMIT, setLimit],
  [CompanyActionType.SET_CURSOR, setCursor],
  [CompanyActionType.SET_ORDERBY, setOrderBy],
  [CompanyActionType.SET_ORDERBY_DIRECTION, setOrderByDirection],
  [CompanyActionType.SET_SEARCH, setSearch],
  [CompanyActionType.SET_SEARCH_FIELD, setSearchField],
  [CompanyActionType.SET_LOADING, setLoading],
  [CompanyActionType.SET_COMPANY_CONTENT_LOADING, setCompanyContentLoading],
  [CompanyActionType.SET_COMPANY_CREATE_LOADING, setCompanyCreateLoading],
  [CompanyActionType.SET_COMPANY_UPDATE_LOADING, setCompanyUpdateLoading],
  [CompanyActionType.SET_COMPANY_CREATE_SUCCESS, setCompanyCreateSuccess],
  [CompanyActionType.SET_COMPANY_UPDATE_SUCCESS, setCompanyUpdateSuccess],
  [CompanyActionType.SET_IS_VENDOR, setIsVendor],
  [CompanyActionType.SET_IS_TOWER_OWNER, setIsTowerOwner],
  [CompanyActionType.SET_COMPANY_CONTENT, setCompanyContent],
  [CompanyActionType.SET_COMPANY_REQUEST_OBJ, setCompanyRequestObj],

  // delete company.
  [CompanyActionType.SET_COMPANY_DELETE_SUCCESS, setCompanyDeleteSuccess],
  [CompanyActionType.SET_COMPANY_DELETE_LOADING, setCompanyDeleteLoading],


  [CompanyActionType.SET_ERROR, setError],

  // companies simple.
  [CompanyActionType.SET_COMPANY_OPTIONS, setCompanyOptions],
  [CompanyActionType.SET_COMPANY_OPTIONS_LOADING, setCompanyOptionsLoading],
  [CompanyActionType.SET_COMPANY_SELECTION_ID, setCompanySelectionId],

  // ownership types.
  [CompanyActionType.SET_OWNERSHIP_TYPES, setOwnershipTypes],
  [CompanyActionType.SET_OWNERSHIP_TYPES_LOADING, setOwnershipTypesLoading],

  [CompanyActionType.CLEAR, clearState],
]);

const reducer = (
  state: CompanyState = initialState,
  action: CompanyAction
): CompanyState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
