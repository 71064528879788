// ca
import {
  Switch,
  Typography,
  Box,
  IconButton,
  Drawer,
  Divider,
  FormGroup,
  FormControlLabel,
  FontAwesome,
  ICON,
} from 'ca-react-component-lib';

// types
import { Operation } from 'src/contexts/TableDrawerContext/types';

// hooks
import { useTableDrawer } from 'src/hooks';

export default function TableDrawer() {
  let {
    dashboardSites,
    managementCompanies,
    managementUsers,
    managementVendors,
    currentOperation,
    changeTableColumns,
    toggleDrawer,
    open,
    reset,
  } = useTableDrawer();
  let tableHead = dashboardSites;

  switch (currentOperation) {
    case Operation.setDashboardSites:
      tableHead = dashboardSites;
      break;
    case Operation.setManagementCompanies:
      tableHead = managementCompanies;
      break;
    case Operation.setManagementUsers:
      tableHead = managementUsers;
      break;
    case Operation.setManagementVendors:
      tableHead = managementVendors;
      break;
    default:
      tableHead = dashboardSites;
  }

  const handleChange = (heading: any, index: any) => {
    const tableRef = [...tableHead];
    tableRef[index].enabled = !tableRef[index].enabled;
    changeTableColumns(currentOperation, tableRef);
  };

  return (
    <Drawer anchor={'right'} open={open} onClose={() => toggleDrawer(currentOperation)}>
      <Box display="flex" flexDirection="column" minWidth={225}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Typography variant="subtitle1">Settings</Typography>
          <Box>
            <IconButton onClick={reset}>
              <FontAwesome icon="arrow-rotate-right" size={ICON.SMALL} />
            </IconButton>
            <IconButton onClick={() => toggleDrawer()}>
              <FontAwesome icon="xmark" />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <Box display="flex" flexDirection="column" p={2}>
          <Typography
            sx={{
              fontWeight: 'fontWeightBold',
              color: 'text.secondary',
            }}
            variant="subtitle2"
            mt={1}
            mb={2}
          >
            Columns
          </Typography>
          <FormGroup>
            {tableHead.map((heading: any, i: any) => (
              <FormControlLabel
                control={
                  <Switch checked={heading.enabled} onChange={() => handleChange(heading, i)} />
                }
                label={`${heading.label}`}
                key={heading.id}
              />
            ))}
          </FormGroup>
        </Box>
      </Box>
    </Drawer>
  );
}
