import { PhotosLoadStrategy } from "ca-react-component-lib";
import { SelectedTiePointLocation } from "src/@types/processingPipeline";

// Enum representing different action types for the processing pipeline
export enum ProcessingPipelineActionType {
  FETCH_ALL_INFO = 'ProcessingPipeline@FETCH_ALL_INFO',
  SET_COMPANY_ID = 'ProcessingPipeline@SET_COMPANY_ID',

  FETCH_WAITING_FOR_IMAGES = 'ProcessingPipeline@FETCH_WAITING_FOR_IMAGES',
  SET_WAITING_FOR_IMAGES = 'ProcessingPipeline@SET_WAITING_FOR_IMAGES',
  SET_WAITING_FOR_IMAGES_LOADING = 'ProcessingPipeline@SET_WAITING_FOR_IMAGES_LOADING',

  FETCH_QUEUE_FOR_PROCESSING = 'ProcessingPipeline@FETCH_QUEUE_FOR_PROCESSING',
  SET_QUEUE_FOR_PROCESSING = 'ProcessingPipeline@SET_QUEUE_FOR_PROCESSING',
  SET_QUEUE_FOR_PROCESSING_LOADING = 'ProcessingPipeline@SET_QUEUE_FOR_PROCESSING_LOADING',

  FETCH_WAIT_FOR_PROCESSING = 'ProcessingPipeline@FETCH_WAIT_FOR_PROCESSING',
  SET_WAIT_FOR_PROCESSING = 'ProcessingPipeline@SET_WAIT_FOR_PROCESSING',
  SET_WAIT_FOR_PROCESSING_LOADING = 'ProcessingPipeline@SET_WAIT_FOR_PROCESSING_LOADING',

  FETCH_PERFORM_QC = 'ProcessingPipeline@FETCH_PERFORM_QC',
  SET_PERFORM_QC = 'ProcessingPipeline@SET_PERFORM_QC',
  SET_PERFORM_QC_LOADING = 'ProcessingPipeline@SET_PERFORM_QC_LOADING',

  FETCH_COMPLETED = 'ProcessingPipeline@FETCH_COMPLETED',
  SET_COMPLETED = 'ProcessingPipeline@SET_COMPLETED',
  SET_COMPLETED_LOADING = 'ProcessingPipeline@SET_COMPLETED_LOADING',

  FETCH_REQUEST_REFLY = 'ProcessingPipeline@FETCH_REQUEST_REFLY',
  SET_REQUEST_REFLY = 'ProcessingPipeline@SET_REQUEST_REFLY',
  SET_REQUEST_REFLY_LOADING = 'ProcessingPipeline@SET_REQUEST_REFLY_LOADING',

  FETCH_REQUEST_MEASUREMENTS = 'ProcessingPipeline@FETCH_REQUEST_MEASUREMENTS',
  SET_REQUEST_MEASUREMENTS = 'ProcessingPipeline@SET_REQUEST_MEASUREMENTS',
  SET_REQUEST_MEASUREMENTS_LOADING = 'ProcessingPipeline@SET_REQUEST_MEASUREMENTS_LOADING',

  FETCH_RE_PROCESS = 'ProcessingPipeline@FETCH_RE_PROCESS',
  SET_RE_PROCESS = 'ProcessingPipeline@SET_RE_PROCESS',
  SET_RE_PROCESS_LOADING = 'ProcessingPipeline@SET_RE_PROCESS_LOADING',

  SET_ERROR = 'ProcessingPipeline@SET_ERROR',
  SET_LOADING = 'ProcessingPipeline@SET_LOADING',
  CLEAR = 'ProcessingPipeline@CLEAR',

  //Tie points 
  SET_EDIT_TIE_POINTS_LOADING = 'ProcessingPipeline@SET_EDIT_TIE_POINTS_LOADING',
  SET_DELETE_TIE_POINTS_LOADING = 'ProcessingPipeline@SET_DELETE_TIE_POINTS_LOADING',

  SET_SELECTED_TIE_POINT_LOCATIONS = 'ProcessingPipeline@SET_SELECTED_TIE_POINT_LOCATIONS',
  SET_NEXT_SELECTED_TIE_POINT_LOCATION_POINTER = 'ProcessingPipeline@SET_NEXT_SELECTED_TIE_POINT_LOCATION_POINTER',
  SET_IS_TIE_POINT_PHOTOS_SHOW = 'ProcessingPipeline@SET_IS_TIE_POINT_PHOTOS_SHOW',
  SCROLL_TO_NEXT_SELECTED_TIE_POINT_PHOTO = 'ProcessingPipeline@SCROLL_TO_NEXT_SELECTED_TIE_POINT_PHOTO',
  SET_TIE_POINT_PHOTO_LOADING_STRATEGY = 'ProcessingPipeline@SET_TIE_POINT_PHOTO_LOADING_STRATEGY',
  SET_TIE_POINT_PHOTO_TAB_INDEX = 'ProcessingPipeline@SET_TIE_POINT_PHOTO_TAB_INDEX',
  SET_EDIT_OR_DELETE_TIE_POINTS_COMPLETED = 'ProcessingPipeline@SET_EDIT_OR_DELETE_TIE_POINTS_COMPLETED',
  SET_IMAGE_LOADING = 'ProcessingPipeline@SET_IMAGE_LOADING',

  // Assessment
  SET_ASSESSMENT_STAGING = 'ProcessingPipeline@SET_ASSESSMENT_STAGING',
  EDIT_SITE_ASSESSMENT_STAGING = 'ProcessingPipeline@EDIT_SITE_ASSESSMENT_STAGING',

  // Moving buckets
  SET_IS_FILTER_BUCKETS_AFTER_MOVING_ASSET = 'ProcessingPipeline@SET_IS_FILTER_BUCKETS_AFTER_MOVING_ASSET',
  UPDATE_BUCKETS_AFTER_SUBMIT_FOR_PROCESSING_SUCCESS = 'Site@UPDATE_BUCKETS_AFTER_SUBMIT_FOR_PROCESSING_SUCCESS',
  SET_IS_EDIT_SITE_ASSESSMENT_STAGING_SUCCESS = 'Site@SET_IS_EDIT_SITE_ASSESSMENT_STAGING_SUCCESS',
  UPDATE_SITE_AFTER_EDIT_SITE_ASSESSMENT_STAGING = 'Site@UPDATE_SITE_AFTER_EDIT_SITE_ASSESSMENT_STAGING',

  SET_SPEED_DIAL_ACTION = 'ProcessingPipeline@SET_SPEED_DIAL_ACTION',
  SET_ACTION_VIEW_OPEN = 'ProcessingPipeline@SET_ACTION_VIEW_OPEN',

}

// Defines the shape of the processing pipeline state
export type ProcessingPipelineState = {
  companyId?: string;

  waiting_for_images?: any;
  waiting_for_images_loading?: boolean;
  queue_for_processing?: any;
  queue_for_processing_loading?: boolean;
  wait_for_processing?: any;
  wait_for_processing_loading?: boolean;
  perform_qc?: any;
  perform_qc_loading?: boolean;
  completed?: any;
  completed_loading?: boolean;
  request_refly?: any;
  request_refly_loading?: boolean;
  request_measurements?: any;
  request_measurements_loading?: boolean;
  re_process?: any;
  re_process_loading?: boolean;

  error?: Error;
  is_loading?: boolean;

  // tie points
  editTiePointsLoading?: boolean;
  deleteTiePointsLoading?: boolean;

  selectedTiePointLocations?: SelectedTiePointLocation[];
  nextSelectedTiePointLocationPointer?: number;
  isTiePointPhotosShow?: boolean;
  tiePointPhotoLoadingStrategy?: PhotosLoadStrategy;
  tiePointPhotoTabIndex?: number;
  isImageLoading?: boolean;

  isFilterBucketsAfterMovingAsset?: boolean;
  isEditSiteAssessmentStagingSuccess?: boolean;

  speedDialAction?: string;
  actionViewOpen?: boolean;
};

// Defines the payload structure for the processing pipeline actions
export type ProcessingPipelinePayload = {
  data?: any;
  fromAction?: string;
  toAction?: string;

  companyId?: string;

  waiting_for_images?: any;
  waiting_for_images_loading?: boolean;
  queue_for_processing?: any;
  queue_for_processing_loading?: boolean;
  wait_for_processing?: any;
  wait_for_processing_loading?: boolean;
  perform_qc?: any;
  perform_qc_loading?: boolean;
  completed?: any;
  completed_loading?: boolean;
  request_refly?: any;
  request_refly_loading?: boolean;
  request_measurements?: any;
  request_measurements_loading?: boolean;
  re_process?: any;
  re_process_loading?: boolean;

  error?: Error;
  is_loading?: boolean;

  selectedTiePointLocations?: SelectedTiePointLocation[];
  nextSelectedTiePointLocationPointer?: number;
  isTiePointPhotosShow?: boolean;
  tiePointPhotoLoadingStrategy?: PhotosLoadStrategy;
  tiePointPhotoTabIndex?: number;
  isImageLoading?: boolean;

  isFilterBucketsAfterMovingAsset?: boolean;
  isEditSiteAssessmentStagingSuccess?: boolean;
  siteAssessment?: string;

  speedDialAction?: string;
  actionViewOpen?: boolean;
};

// Defines the structure of a processing pipeline action
export type ProcessingPipelineAction = {
  payload?: ProcessingPipelinePayload;
  type: ProcessingPipelineActionType;
};

// Defines the signature for a reducer function in the processing pipeline
export type ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ProcessingPipelineState;
