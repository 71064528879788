import api from './api';
import { SiteImage, SitePagination, StructureTypeContent } from 'src/@types/site';
import { QueueForProcessingResponse, StructureTypeResponseSchema } from './types';
import { SuccessError, SuccessErrorResponse } from 'src/@types/root';


export const fetchAllSites = async (payload: any): Promise<SitePagination> => {
  try {

    const {
      limit,
      cursor,
      search,
      search_field,
      order_by_direction,
      order_by,
      keyword_search,
      search_terms,
      structure_types,
      tower_owners,
      assessment_tags,
      flood_zone_designations,
      drone_vendors,
      structure_height_from,
      structure_height_to,
      is_lease_space,
      qc_status,
      user_id,
      only_sites_with_assessments,
    } = payload;

    const response = await api.fetchAllSites(
      limit,
      cursor,
      search,
      search_field,
      order_by_direction,
      order_by,
      keyword_search,
      search_terms,
      structure_types,
      tower_owners,
      assessment_tags,
      flood_zone_designations,
      drone_vendors,
      structure_height_from,
      structure_height_to,
      is_lease_space,
      qc_status,
      user_id,
      only_sites_with_assessments
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};


export const fetchSiteByName = async (siteId: string): Promise<any> => {
  try {
    const response = await api.fetchSiteByName(siteId);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchStructureTypeOptions = async (): Promise<StructureTypeContent> => {
  try {
    const response: StructureTypeResponseSchema = await api.fetchStructureTypeOptions();
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchAssetsNearby = async (
  siteId: string,
  radius: number,
  onlyLeaseSpace?: boolean,
  removeZeroDistance?: boolean
): Promise<any> => {
  try {
    const response = await api.fetchAssetsNearby(siteId, radius, onlyLeaseSpace, removeZeroDistance);
    return response.filter((item) => {
      if (!item.latitude || !item.longitude) return false;
      const { latitude, longitude } = item;
      return (
        longitude < 180 && longitude > -180 && latitude < 90 && latitude > -90
      );
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
};

//bulk insert

export const fetchBulkInsertTemplate = async (extension: string): Promise<any> => {
  try {
    const response: any = await api.fetchBulkInsertTemplate(extension);
    return response;
  } catch (e) {
    throw e;
  }
};

export const bulkInsertSite = async (payload: any): Promise<any> => {
  try {
    const form_data = new FormData();
    form_data.append('file', payload.file[0]);

    const response = await api.bulkInsert({
      file: form_data,
      owner_id: payload.owner_id,
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const createSite = async (data: any): Promise<SuccessError> => {
  try {
    const response: SuccessErrorResponse = await api.createSite(data);

    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const editSite = async (data: any, siteId: string): Promise<any> => {
  try {
    const response = await api.editSite(data, siteId);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchSiteAssessmentStaging = async (
  assessmentId: string
): Promise<any> => {
  try {
    const response = await api.fetchSiteAssessmentStaging(
      assessmentId
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};


export const createSiteAssessment = async (data: any, siteId: string): Promise<any> => {
  try {
    const response = await api.createSiteAssessment(data, siteId);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const editSiteAssessment = async (data: any, assessmentId: string): Promise<any> => {
  try {
    const response = await api.editSiteAssessment(data, assessmentId);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const deleteSiteAssessmentStaging = async (assesmentId: string): Promise<any> => {
  try {
    const response = await api.deleteSiteAssessmentStaging(assesmentId);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const createSiteUser = async (data: any, siteId: string): Promise<any> => {
  try {
    const response = await api.createSiteUser(data, siteId);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const editSiteUser = async (data: any, siteUserRoleId: string): Promise<any> => {
  try {
    const response = await api.editSiteUser(data, siteUserRoleId);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const deleteSiteUser = async (siteUserId: string): Promise<any> => {
  try {
    const response = await api.deleteSiteUser(siteUserId);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const submitQueueForProcessingData = async (processingData: any): Promise<any> => {
  try {
    const response: QueueForProcessingResponse = await api.submitQueueForProcessingData(
      processingData
    );
    return response.detail;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const parseSiteImageResponseToSiteImage = (image: any): SiteImage => ({
  absoluteAltitude: image.metadata.abs_alt,
  timestamp: image.metadata.timestamp ?? null,
  exif: null,
  folder: image.file_path,
  originalFolderName: image.file_path,
  gymbal: image.metadata.gimbal_yaw,
  id: image.id,
  pictureFile: image.file_name,
  pictureLargeLink: image.sizes.large.url,
  pictureLink: image.sizes.original.url,
  points: image.tie_points[0] ? {
    ax: image.tie_points[0].point_ax,
    ay: image.tie_points[0].point_ay,
    bx: image.tie_points[0].point_bx,
    by: image.tie_points[0].point_by,
    id: image.tie_points[0].id,
  } : null,
  relativeAltitude: image.metadata.abs_alt,
  thumbnail: image.sizes.small.url,
});

export const getAssessmentPhotos = async (assessmentId: string, tab?: string, limit?: number, offset?: number): Promise<SiteImage[]> => {
  try {
    const response: any = await api.getAssessmentImages(assessmentId, tab, limit, offset);
    const result = await new Promise<SiteImage[]>((resolve, reject) => {
      try {
        let images: SiteImage[] = [];
        response.images
          .sort((a, b) => {
            // timestamp can be null.
            if (a.timestamp && b.timestamp) {
              return a.timestamp - b.timestamp;
            }
            if (a.file_name < b.file_name) {
              return -1;
            }
            if (a.file_name > b.file_name) {
              return 1;
            }
            return 0;
          })
          .forEach((image) => {
            const newImage: SiteImage = parseSiteImageResponseToSiteImage(image);
            images = [...images, newImage];
          });

        resolve(images);
      } catch (e) {
        reject(e);
      }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getAssessmentPhoto = async (assessmentId: string): Promise<object> => {
  try {
    const response: any = await api.getAssessmentImageSummary(assessmentId);
    return {
      file_path_summary: response.image_summary.file_path_summary,
      images_count: response.image_summary.images_count,
      sample_image: {
        absoluteAltitude: response.image_summary.sample_image?.metadata?.abs_alt,
        timestamp: response.image_summary.sample_image?.metadata?.timestamp ?? null,
        exif: null,
        folder: response.image_summary.sample_image?.file_path,
        originalFolderName: response.image_summary.sample_image?.file_path,
        gymbal: response.image_summary.sample_image?.metadata?.gimbal_yaw,
        id: response.image_summary.sample_image?.id,
        pictureFile: response.image_summary.sample_image?.file_name,
        pictureLargeLink: response.image_summary.sample_image?.sizes.large.url,
        pictureLink: response.image_summary.sample_image?.sizes.original.url,
        points: [],
        relativeAltitude: response.image_summary.sample_image?.metadata?.abs_alt,
        thumbnail: response.image_summary.sample_image?.sizes?.small?.url,
      }
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const setAssessmentPhotoTiePoint = async (assessmentId: string, payload: any): Promise<any> => {
  try {
    const { tie_point } = await api.setAssessmentPhotoTiePoint(assessmentId, {
      "tie_point": {
        "point_ax": payload.Ax,
        "point_ay": payload.Ay,
        "point_bx": payload.Bx,
        "point_by": payload.By,
        "image_id": payload.ImageId
      }
    });
    return tie_point;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const deleteAssessmentPhotoTiePoint = async (assessmentId: string, tiePointId: string): Promise<any> => {
  try {
    const response = await api.deleteAssessmentPhotoTiePoint(assessmentId, tiePointId);
    return {
      error: response.Error,
      success: response.Success,
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getJobProgress = async (assessmentId: string): Promise<any> => {
  try {
    const response: any = await api.getJobProgress(assessmentId);
    return response;
  } catch (e) {
    console.error(e);
    // throw e;
  }
};

export const triggerAzureImport = async (assessmentId: string, url: string): Promise<any> => {
  try {
    const response = await api.triggerAzureImport(assessmentId, {
      "paths": {
        "target_path": url,
      }
    });

    return {
      error: response.Error,
      success: response.Success,
      message: response.message,
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const triggerAzureExport = async (assessmentId: string, url: string): Promise<any> => {
  try {
    const response = await api.triggerAzureExport(assessmentId, {
      "azure_url": url,
    });

    return {
      error: response.Error,
      success: response.Success,
      message: response.message,
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const completeUpload = async (assessmentId: string): Promise<any> => {
  try {
    const response = await api.forceCompleteUpload(assessmentId);

    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getImageImporterStatus = async (): Promise<any> => {
  try {
    const response = await api.getImageImporterStatus();

    return response.image_importer_statuses;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getImageExporterStatus = async (): Promise<any> => {
  try {
    const response = await api.getImageExporterStatus();

    return response.image_exporter_statuses;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getModelProcessingQueues = async (): Promise<any> => {
  try {
    const response = await api.getModelProcessingQueues();

    return response.queues;
  } catch (e) {
    console.error(e);
    throw e;
  }
};