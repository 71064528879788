import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import processingPipelineSelectors from 'src/store/ProcessingPipeline/selectors';
import processingPipelineActions from 'src/store/ProcessingPipeline/actions';
import { SelectedTiePointLocation } from 'src/@types/processingPipeline';
import { PhotosLoadStrategy } from 'ca-react-component-lib';
import { setTodoCompany } from 'src/storage/TodoCompany';

/**
 * Custom hook to manage and interact with the processing pipeline state.
 * - Uses Redux hooks to access state and dispatch processingPipelineActions.
 * - Provides selectors for various pieces of state related to the processing pipeline.
 * - Provides action dispatchers for fetching different stages of the processing pipeline.
 */
const useProcessingPipeline = () => {
  const companyId = useSelector(processingPipelineSelectors.getCompanyId);

  // Selector to get the 'waitingForUpload' data from the Redux store
  const waitingForImages = useSelector(processingPipelineSelectors.getWaitingForImages);
  // Selector to check if the 'waitingForUpload' data is currently being loaded
  const isWaitingForImagesLoading = useSelector(
    processingPipelineSelectors.isWaitingForImagesLoading
  );

  // Selector to get the 'queueForProcessing' data from the Redux store
  const queueForProcessing = useSelector(processingPipelineSelectors.getQueueForProcessing);
  // Selector to check if the 'queueForProcessing' data is currently being loaded
  const isQueueForProcessingLoading = useSelector(
    processingPipelineSelectors.isQueueForProcessingLoading
  );

  // Selector to get the 'waitForProcessing' data from the Redux store
  const waitForProcessing = useSelector(processingPipelineSelectors.getWaitForProcessing);
  // Selector to check if the 'waitForProcessing' data is currently being loaded
  const isWaitForProcessingLoading = useSelector(
    processingPipelineSelectors.isWaitForProcessingLoading
  );

  // Selector to get the 'performQC' data from the Redux store
  const performQC = useSelector(processingPipelineSelectors.getPerformQC);
  // Selector to check if the 'performQC' data is currently being loaded
  const isPerformQCLoading = useSelector(processingPipelineSelectors.isPerformQCLoading);

  // Selector to get the 'complete' data from the Redux store
  const completed = useSelector(processingPipelineSelectors.getCompleted);
  // Selector to check if the 'complete' data is currently being loaded
  const isCompletedLoading = useSelector(processingPipelineSelectors.isCompletedLoading);

  // Selector to get the 'refly' data from the Redux store
  const refly = useSelector(processingPipelineSelectors.getRefly);

  // Selector to check if the 'refly' data is currently being loaded
  const isReflyLoading = useSelector(processingPipelineSelectors.isReflyLoading);

  // Selector to get the 'measurements' data from the Redux store
  const measurements = useSelector(processingPipelineSelectors.getRequestMeasurements);

  // Selector to check if the 'measurements' data is currently being loaded
  const isMeasurementsLoading = useSelector(processingPipelineSelectors.isRequestMeasurementsLoading);

  // Selector to get the 'reprocess' data from the Redux store
  const reprocess = useSelector(processingPipelineSelectors.getReProcess);

  // Selector to check if the 'reprocess' data is currently being loaded
  const isReprocessLoading = useSelector(processingPipelineSelectors.isReProcessLoading);

  // Selector to check if any part of the processing pipeline is loading
  const isLoading = useSelector(processingPipelineSelectors.isLoading);

  const isEditTiePointsLoading = useSelector(processingPipelineSelectors.isEditTiePointsLoading);
  const isDeleteTiePointsLoading = useSelector(processingPipelineSelectors.isDeleteTiePointsLoading);
  const isImageLoading = useSelector(processingPipelineSelectors.getImageLoading);

  const selectedTiePointLocations = useSelector(processingPipelineSelectors.getSelectedTiePointLocations);
  const nextSelectedTiePointLocationPointer = useSelector(
    processingPipelineSelectors.getNextSelectedTiePointLocationPointer
  );
  const isTiePointPhotosShow = useSelector(processingPipelineSelectors.getIsTiePointPhotosShow);
  const tiePointPhotoLoadingStrategy = useSelector(processingPipelineSelectors.getTiePointPhotoLoadingStrategy);
  const tiePointPhotoTabIndex = useSelector(processingPipelineSelectors.getTiePointPhotoTabIndex);

  const isFilterBucketsAfterMovingAsset = useSelector(
    processingPipelineSelectors.getIsFilterBucketsAfterMovingAsset
  );

  const speedDialAction = useSelector(processingPipelineSelectors.getSpeedDialAction);

  const actionViewOpen = useSelector(processingPipelineSelectors.getActionViewOpen);

  const isEditSiteAssessmentStagingSuccess = useSelector(processingPipelineSelectors.getIsEditSiteAssessmentStagingSuccess);

  // Using the `useDispatch` hook to get the dispatch function from the Redux store
  const dispatch = useDispatch();

  const fetchAllInfo = useCallback(
    (companyId?: string) => {
      dispatch(processingPipelineActions.fetchAllInfo(companyId));
    },
    [dispatch]
  );

  // Memoized callback to dispatch the action to fetch 'waitingForUpload' data
  const fetchWaitingForUpload = useCallback(() => {
    dispatch(processingPipelineActions.fetchWaitingForImages());
  }, [dispatch]);

  // Memoized callback to dispatch the action to fetch 'queueForProcessing' data
  const fetchQueueForProcessing = useCallback(() => {
    dispatch(processingPipelineActions.fetchQueueForProcessing());
  }, [dispatch]);

  // Memoized callback to dispatch the action to fetch 'waitForProcessing' data
  const fetchWaitForProcessing = useCallback(() => {
    dispatch(processingPipelineActions.fetchWaitForProcessing());
  }, [dispatch]);

  // Memoized callback to dispatch the action to fetch 'performQC' data
  const fetchPerformQC = useCallback(() => {
    dispatch(processingPipelineActions.fetchPerformQC());
  }, [dispatch]);

  // Memoized callback to dispatch the action to fetch 'complete' data
  const fetchCompleted = useCallback(() => {
    dispatch(processingPipelineActions.fetchCompleted());
  }, [dispatch]);


  // Memoized callback to dispatch the action to fetch 'refly' data
  const fetchRefly = useCallback(() => {
    dispatch(processingPipelineActions.fetchRequestRefly());
  }, [dispatch]);

  // Memoized callback to dispatch the action to fetch 'measurements' data
  const fetchMeasurements = useCallback(() => {
    dispatch(processingPipelineActions.fetchRequestMeasurements());
  }, [dispatch]);

  // Memoized callback to dispatch the action to fetch 'reprocess' data
  const fetchReprocess = useCallback(() => {
    dispatch(processingPipelineActions.fetchReProcess());
  }, [dispatch]);


  const editSiteAssessmentStaging = useCallback(
    (
      assessmentId: string,
      site: any,
      fromAction,
      toAction,
      payload: any,
      speedDialAction?: string
    ) => {
      dispatch(
        processingPipelineActions.editSiteAssessmentStaging(
          assessmentId,
          site,
          fromAction,
          toAction,
          payload,
          speedDialAction
        )
      );
    },
    [dispatch]
  );


  const setSelectedTiePointLocations = useCallback(
    (selectedTiePointLocations?: SelectedTiePointLocation[]) => {
      dispatch(processingPipelineActions.setSelectedTiePointLocations(selectedTiePointLocations));
    },
    [dispatch]
  );

  const setNextSelectedTiePointLocationPointer = useCallback(
    (nextSelectedTiePointLocationPointer: number) => {
      dispatch(processingPipelineActions.setNextSelectedTiePointLocationPointer(nextSelectedTiePointLocationPointer));
    },
    [dispatch]
  );

  const scrollToNextSelectedTiePointPhoto = useCallback(() => {
    dispatch(processingPipelineActions.scrollToNextSelectedTiePointPhoto());
  }, [dispatch]);

  const setTiePointPhotoLoadingStrategy = useCallback(
    (tiePointPhotoLoadingStrategy: PhotosLoadStrategy) => {
      dispatch(processingPipelineActions.setTiePointPhotoLoadingStrategy(tiePointPhotoLoadingStrategy));
    },
    [dispatch]
  );

  const setImageLoading = useCallback(
    (isImageLoading: boolean) => {
      dispatch(processingPipelineActions.setImageLoading(isImageLoading));
    },
    [dispatch]
  );


  const updateBucketsAfterSubmitForProcessingSuccess = useCallback(
    () => {
      dispatch(processingPipelineActions.updateBucketsAfterSubmitForProcessingSuccess());
    },
    [dispatch]
  );

  const setSpeedDialAction = useCallback(
    (speedDialAction?: string) => {
      dispatch(processingPipelineActions.setSpeedDialAction(speedDialAction));
    },
    [dispatch]
  );


  const setActionViewOpen = useCallback(
    (actionViewOpen: boolean) => {
      dispatch(processingPipelineActions.setActionViewOpen(actionViewOpen));
    },
    [dispatch]
  );

  const setCompanyId = useCallback(
    (companyId: string) => {
      setTodoCompany({ label: '', value: companyId });
      dispatch(processingPipelineActions.setCompanyId(companyId));
    },
    [dispatch]
  );

  const clearState = useCallback(() => {
    dispatch(processingPipelineActions.clearState());
  }, [dispatch]);





  // Return an object containing the current state and action dispatchers
  // This allows components to easily access the processing pipeline state and dispatch processingPipelineActions
  return {
    fetchAllInfo,

    waitingForImages,
    isWaitingForImagesLoading,
    queueForProcessing,
    isQueueForProcessingLoading,
    waitForProcessing,
    isWaitForProcessingLoading,
    performQC,
    isPerformQCLoading,
    completed,
    isCompletedLoading,
    refly,
    isReflyLoading,
    measurements,
    isMeasurementsLoading,
    reprocess,
    isReprocessLoading,
    isLoading,
    fetchWaitingForUpload,
    fetchQueueForProcessing,
    fetchWaitForProcessing,
    fetchPerformQC,
    fetchCompleted,
    fetchRefly,
    fetchMeasurements,
    fetchReprocess,

    isEditTiePointsLoading,
    isDeleteTiePointsLoading,
    isImageLoading,
    selectedTiePointLocations,
    nextSelectedTiePointLocationPointer,
    isTiePointPhotosShow,
    tiePointPhotoLoadingStrategy,
    tiePointPhotoTabIndex,
    isEditSiteAssessmentStagingSuccess,
    editSiteAssessmentStaging,
    setSelectedTiePointLocations,
    setNextSelectedTiePointLocationPointer,
    scrollToNextSelectedTiePointPhoto,
    setTiePointPhotoLoadingStrategy,
    setImageLoading,

    updateBucketsAfterSubmitForProcessingSuccess,
    isFilterBucketsAfterMovingAsset,

    setSpeedDialAction,
    speedDialAction,

    setActionViewOpen,
    actionViewOpen,

    setCompanyId,
    companyId,
    clearState



  };
};

export default useProcessingPipeline;
