import request from 'src/services/request';
import endpoints from './endpoints';

import { CompanyBodyRequestSchema, RevokedCompanyUserBodySchema, CompanyUserBodyRequestSchema } from './types';

const api = {
  fetchAllCompanies: async (
    limit: number,
    cursor?: string,
    search?: string,
    search_field?: string,
    order_by_direction?: string,
    order_by?: string,
    is_tower_owner?: boolean,
    is_vendor?: boolean
  ) => {
    let queryString = `?limit=${limit}`;

    if (cursor) queryString += `&cursor=${cursor}`;
    if (search) queryString += `&search=${search}`;
    if (search_field) queryString += `&search_field=${search_field}`;
    if (order_by_direction) queryString += `&order_by_direction=${order_by_direction}`;
    if (order_by) queryString += `&order_by=${order_by}`;
    if (is_tower_owner) queryString += `&is_tower_owner=${is_tower_owner}`;
    if (is_vendor) queryString += `&is_vendor=${is_vendor}`;

    return request(
      `${endpoints.company}/${queryString}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    );
  },
  fetchCompanyContent: async (company_id: string) =>
    request(`${endpoints.company}/${company_id}/`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),
  postCompany: async (payload: CompanyBodyRequestSchema) =>
    request(`${endpoints.company}/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),
  patchCompany: async (company_id: string, payload: CompanyBodyRequestSchema) =>
    request(`${endpoints.company}/${company_id}/`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),
  deleteCompany: async (company_id: string) =>
    request(`${endpoints.company}/${company_id}/`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }),
  deleteCompanyCustomerSuccess: async (company_id: string) =>
    request(`${endpoints.company}/${company_id}/customer-success-access/`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }),
  deleteCompanyGuestAccess: async (company_id: string) =>
    request(`${endpoints.company}/${company_id}/guest-access/`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }),

  deleteCompanyAssetGroupPermission: async (company_id: string) =>
    request(`${endpoints.company}/${company_id}/asset-group-permission/`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }),

  fetchOwnershipTypeOptions: async () =>
    request(endpoints.company_ownership, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),
  fetchCompanyOptions: async (
    search?: string,
    is_vendor?: boolean,
    is_tower_owner?: boolean
  ) => {
    const queryParams: string[] = [];

    if (is_vendor) queryParams.push(`is_vendor=${is_vendor}`);
    if (is_tower_owner) queryParams.push(`is_tower_owner=${is_tower_owner}`);
    if (search) queryParams.push(`search=${search}`);

    const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

    return request(`${endpoints.company_options}${queryString}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    });
  },
  //Manage Access
  fetchAllCompanyUsers: async (
    limit: number,
    cursor?: string,
    search?: string,
    search_field?: string,
    order_by_direction?: string,
    order_by?: string,
    company_id?: string,
    only_external?: boolean,
  ) => {
    let queryString = `?limit=${limit}`;

    if (cursor) queryString += `&cursor=${cursor}`;
    if (search) queryString += `&search=${search}`;
    if (search_field) queryString += `&search_field=${search_field}`;
    if (order_by_direction) queryString += `&order_by_direction=${order_by_direction}`;
    if (order_by) queryString += `&order_by=${order_by}`;
    if (company_id) queryString += `&company_id=${company_id}`;
    if (only_external) queryString += `&only_external=${only_external}`;

    return request(
      `${endpoints.company_access}${queryString}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    );
  },
  postCompanyUser: async (company_id: string, payload: CompanyUserBodyRequestSchema) =>
    request(`${endpoints.company_access}${company_id}/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),
  deleteCompanyUser: async (company_id: string, payload: RevokedCompanyUserBodySchema) =>
    request(`${endpoints.company_access}${company_id}/`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),
};

export default api;
