import { takeLatest } from 'redux-saga/effects';

// types
import { SiteActionType } from '../types';

import { createSite, bulkInsert } from './add';


import { fetchStructureSelectOptions } from './structure';

import { fetchSite, fetchCurrentSiteAssessmentId, fetchSiteAssessmentStaging } from './sites';

import {
  deletePhotoTiePoints,
  editPhotoTiePoints,
  editSite,
  submitQueueForProcessing,
  deleteSiteAssessmentStaging,
  startAzureImport,
  startAzureExport,
  forceCompleteUpload,
  deleteSiteUser,
  editSiteAssessmentStaging
} from './edit';

const sagas = [
  takeLatest(SiteActionType.FETCH_SITE, fetchSite),
  takeLatest(SiteActionType.SET_CURRENT_ASSESSMENT_ID, fetchCurrentSiteAssessmentId),
  takeLatest(SiteActionType.FETCH_STRUCTURE_SELECT_OPTIONS, fetchStructureSelectOptions),
  takeLatest(SiteActionType.FETCH_SITE_ASSESSMENT_STAGING, fetchSiteAssessmentStaging),
  takeLatest(SiteActionType.CREATE_SITE, createSite),
  takeLatest(SiteActionType.EDIT_SITE, editSite),
  takeLatest(SiteActionType.EDIT_PHOTO_TIE_POINTS, editPhotoTiePoints),
  takeLatest(SiteActionType.START_AZURE_IMPORT, startAzureImport),
  takeLatest(SiteActionType.START_AZURE_EXPORT, startAzureExport),
  takeLatest(SiteActionType.FORCE_COMPLETE_UPLOAD, forceCompleteUpload),
  takeLatest(SiteActionType.DELETE_PHOTO_TIE_POINTS, deletePhotoTiePoints),
  takeLatest(SiteActionType.SUBMIT_QUEUE_FOR_PROCESSING, submitQueueForProcessing),
  takeLatest(SiteActionType.DELETE_SITE_ASSESSMENT_STAGING, deleteSiteAssessmentStaging),
  takeLatest(SiteActionType.DELETE_SITE_USER, deleteSiteUser),
  takeLatest(SiteActionType.BULK_INSERT, bulkInsert),
  takeLatest(SiteActionType.EDIT_SITE_ASSESSMENT_STAGING, editSiteAssessmentStaging),
];


export default sagas;
