import { getData, setData, clearData } from 'ca-react-util-lib';

// types
import { LabelValue, KEYS } from 'src/@types/root';

export const getTodoCompany = (): LabelValue | undefined =>
  getData(KEYS.TODO_COMPANY) === null ? undefined : JSON.parse(getData(KEYS.TODO_COMPANY) || '{}');

export const setTodoCompany = (company: LabelValue) =>
  setData(KEYS.TODO_COMPANY, JSON.stringify(company));

export const clearTodoCompany = () => clearData(KEYS.TODO_COMPANY);
