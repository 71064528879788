import { put, takeLatest, call, select } from 'redux-saga/effects';

// actions
import actions from './actions';
import toastActions from '../Toast/actions';

// selectors
import selectors from './selectors';

// services
import * as usersServices from 'src/servicesV2/user';

// types
import { TOAST_SEVERITY } from 'src/@types/toast';
import { SiteRoleResponse, UserBulkCreateResponseSchema, UserOptionsContent, UserPagination, UserWithCompanyResponse } from 'src/@types/user';
import { UsersAction, UsersActionType } from './types';

export function* fetchAllUsers() {
  const limit: number = yield select(selectors.getLimit);
  const cursor: string = yield select(selectors.getCursor);
  const search: string = yield select(selectors.getSearch);
  const searchField: string = yield select(selectors.getSearchField);
  const order_by: string = yield select(selectors.getOrderBy);
  const order_by_direction: string = yield select(selectors.getOrderByDirection);
  const company_id: string = yield select(selectors.getCompanyId);
  const exclude_invited: boolean = yield select(selectors.getExcludeInvited);
  const roles: string[] = yield select(selectors.getRoles);

  yield put(actions.setLoading(true));

  try {
    const response: UserPagination = yield call(
      usersServices.fetchAllUsers,
      limit,
      cursor,
      search,
      searchField,
      order_by_direction,
      order_by,
      company_id,
      exclude_invited,
      roles

    );
    yield put(actions.setUsers(response));
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* fetchUserContent({ payload }: UsersAction) {
  try {
    if (!payload || !payload.user_id) {
      return;
    }

    const { user_id } = payload;

    yield put(actions.setUserContentLoading(true));
    const response: UserWithCompanyResponse = yield call(usersServices.fetchUserContent, user_id);
    if (response) {
      yield put(actions.setUserContent(response));
    }
  } catch (e: any) {
    yield put(actions.setError(e));
  } finally {
    yield put(actions.setUserContentLoading(false));
  }
}


export function* postUser({ payload }: UsersAction) {
  try {
    if (!payload || !payload.user) return;

    yield put(actions.setUserCreateLoading(true));

    const postUserResponse: UserWithCompanyResponse = yield call(usersServices.postUser, payload.user);

    if (postUserResponse) {
      yield put(toastActions.setToast('Create Success!'));
      yield put(actions.setUserCreateSuccess(true));
    } else {
      yield put(
        toastActions.setToast(
          'The user you are trying to create already exists',
          TOAST_SEVERITY.ERROR,
          { autoHideDuration: 10000 }
        )
      );
      yield put(actions.setUserCreateSuccess(false));
    }
  } catch (e) {
    console.error(e);
    yield put(toastActions.setToast('Create Failed!', TOAST_SEVERITY.ERROR));
  } finally {
    yield put(actions.setUserCreateLoading(false));
    yield put(actions.setUserContent(undefined));
    yield put(toastActions.clearState());
    yield put(actions.setUserCreateSuccess(undefined));
  }
}

export function* patchUser({ payload }: UsersAction) {
  try {
    if (!payload || !payload.user_id || !payload.user) {
      return;
    }

    yield put(actions.setUserUpdateLoading(true));
    const patchUserResponse: UserWithCompanyResponse = yield call(usersServices.patchUser, payload.user_id, payload.user);

    if (patchUserResponse.user?.id) {
      yield put(toastActions.setToast('Edit Success!'));
      yield put(actions.setUserUpdateSuccess(true));
    } else {
      yield put(toastActions.setToast('Edit Failed!', TOAST_SEVERITY.ERROR));
      yield put(actions.setUserUpdateSuccess(false));
      return;
    }
  } catch (e) {
    console.error(e);
    yield put(toastActions.setToast('Edit Failed!', TOAST_SEVERITY.ERROR));
  } finally {
    yield put(actions.setUserUpdateLoading(false));
    yield put(actions.setUserContent(undefined));
    yield put(toastActions.clearState());
    yield put(actions.setUserUpdateSuccess(undefined));
  }
}


export function* deleteUser({ payload }: UsersAction) {
  try {
    if (!payload || !payload.user_id) {
      return;
    }

    yield put(actions.setUserDeleteLoading(true));
    const response = yield call(usersServices.deleteUser, payload.user_id);

    if (response) {
      yield put(toastActions.setToast('Delete Success!'));
      yield put(actions.setUserDeleteSuccess(true));
    } else {
      yield put(toastActions.setToast('Delete Failed!', TOAST_SEVERITY.ERROR));
      yield put(actions.setUserDeleteSuccess(false));
    }
  } catch (e) {
    console.error(e);
    yield put(toastActions.setToast('Delete Failed!', TOAST_SEVERITY.ERROR));
  } finally {
    yield put(actions.setUserDeleteLoading(false));
    yield put(toastActions.clearState());
    yield put(actions.setUserDeleteSuccess(undefined));
  }
}

export function* fetchUserOptions({ payload }: UsersAction) {
  const search: string = yield select(selectors.getSearch);
  let min_role_level: number = yield select(selectors.getMinRoleLevel);
  let max_role_level: number = yield select(selectors.getMaxRoleLevel);


  if (payload) {
    const { min_role_level: min, max_role_level: max } = payload;
  
    if (min !== undefined) {
      min_role_level = min;
    }
    if (max !== undefined) {
      max_role_level = max;
    }
  }


  yield put(actions.setUserOptionsLoading(true));
  try {
    const response: UserOptionsContent = yield call(
      usersServices.fetchUserOptions,
      search,
      min_role_level,
      max_role_level
    );
    yield put(actions.setUserOptions(response));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(actions.setUserOptionsLoading(false));
  }
}

export function* fetchRoleTypes() {
  try {
    yield put(actions.setRoleTypesLoading(true));

    const response: SiteRoleResponse = yield call(usersServices.fetchRoles);
    yield put(actions.setRoleTypes(response));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(actions.setRoleTypesLoading(false));
  }
}

export function* bulkInsert({ payload }: UsersAction) {
  try {
    if (!payload || !payload.file) {
      return;
    }

    const { file, company_id } = payload;

    yield put(actions.setBulkInsertLoading(true));

    const response: UserBulkCreateResponseSchema = yield call(usersServices.bulkInsert, { file, company_id });

    yield put(actions.setBulkInsertResult(response));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(actions.setBulkInsertLoading(false));
  }
}


export function* inviteUsersById({ payload }: UsersAction) {
  if (!payload) return;

  try {
    yield put(actions.setUserContentLoading(true));
    const { user_ids } = payload;
    const response: any = yield call(usersServices.inviteUsersById, user_ids);

    if (response.successes.length > 0) {
      const successMessages = response.successes.map(
        success => success.replace("Successfully sent invite email to '", "").replace("'", "")
      );
      yield put(toastActions.setToast(`${successMessages.join(", ")} were successfully invited!`));
    }

    if (response.errors.length > 0) {
      const errorMessages = response.errors.map(
        error => error.replace("Failed to send invite email to '", "").replace("'", "")
      );
      yield put(toastActions.setToast(`${errorMessages.join(", ")} could not be invited`, TOAST_SEVERITY.ERROR));
    }

    if (response.successes.length === 0 && response.errors.length === 0) {
      yield put(toastActions.setToast("No users were invited due to an unknown error", TOAST_SEVERITY.ERROR));
    }
  } catch (e) {
    yield put(actions.setError(e));
    yield put(toastActions.setToast("Failed to invite users due to a system error", TOAST_SEVERITY.ERROR));
  } finally {
    yield put(actions.setUserContentLoading(false));
    yield put(toastActions.clearState());
  }
}

//roles options
export function* fetchRoleOptions() {
  try {
    yield put(actions.setRoleOptionsLoading(true));

    const response: any = yield call(usersServices.fetchRoleOptions);
    yield put(actions.setRoleOptions(response));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(actions.setRoleOptionsLoading(false));
  }
}




const sagas = [
  takeLatest(UsersActionType.FETCH_ALL_USERS, fetchAllUsers),

  // edit user.
  takeLatest(UsersActionType.FETCH_USER_CONTENT, fetchUserContent),
  takeLatest(UsersActionType.SET_INVITE_USER_BY_ID, inviteUsersById),
  takeLatest(UsersActionType.UPDATE_USER, patchUser),
  takeLatest(UsersActionType.CREATE_USER, postUser),
  takeLatest(UsersActionType.DELETE_USER, deleteUser),

  takeLatest(UsersActionType.FETCH_USER_OPTIONS, fetchUserOptions),

  // role types.
  takeLatest(UsersActionType.FETCH_ROLES_TYPES, fetchRoleTypes),

  // bulk insert.
  takeLatest(UsersActionType.BULK_INSERT, bulkInsert),

  //role options
  takeLatest(UsersActionType.FETCH_ROLE_OPTIONS, fetchRoleOptions),
];

export default sagas;
