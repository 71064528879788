import { MAIN_PATHS } from 'src/routes/paths';

export const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Client',
    linkTo: `${process.env.REACT_APP_TENANT_URL}`,
    target: true
  },
  {
    label: 'Profile',
    linkTo: MAIN_PATHS.profile,
  },
];
