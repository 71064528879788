import { SiteRoleResponse, UserBodyRequestSchema, UserOptionsContent, UserPagination, UserWithCompanyResponse } from 'src/@types/user';
import { UsersAction, UsersActionType } from './types';

const actions = {
  fetchAllUsers: (): UsersAction => ({
    type: UsersActionType.FETCH_ALL_USERS,
  }),
  setUsers: (user_pagination: UserPagination): UsersAction =>({
    payload: { user_pagination },
    type: UsersActionType.SET_USERS,
  }),
  fetchUserContent: (user_id: string): UsersAction => ({
    payload: { user_id },
    type: UsersActionType.FETCH_USER_CONTENT,
  }),
  setUserContent: (user_content?: UserWithCompanyResponse): UsersAction => ({
    payload: { user_content },
    type: UsersActionType.SET_USER_CONTENT,
  }),
  setLimit: (limit: number): UsersAction => ({
    payload: { limit },
    type: UsersActionType.SET_LIMIT,
  }),
  setCursor: (cursor: string): UsersAction => ({
    payload: { cursor },
    type: UsersActionType.SET_CURSOR,
  }),
  setOrderBy: (order_by: 'company_name' | 'full_name' | 'email' | 'phone_number' | 'invited' | 'date_created'): UsersAction => ({
    payload: { order_by },
    type: UsersActionType.SET_ORDERBY,
  }),
  setOrderByDirection: (order_by_direction: 'asc' | 'desc'): UsersAction => ({
    payload: { order_by_direction },
    type: UsersActionType.SET_ORDERBY_DIRECTION,
  }),
  setSearch: (search: string): UsersAction=> ({
    payload: { search },
    type: UsersActionType.SET_SEARCH,
  }),
  setSearchField: (search_field: 'company_name' | 'full_name' | 'email'): UsersAction=> ({
    payload: { search_field },
    type: UsersActionType.SET_SEARCH_FIELD,
  }),
  setCompanyId: (company_id?: string): UsersAction => ({
    payload: { company_id },
    type: UsersActionType.SET_COMPANY_ID,
  }),
  setExcludeInvited: (exclude_invited?: boolean): UsersAction => ({
    payload: { exclude_invited },
    type: UsersActionType.SET_EXCLUDE_INVITED,
  }),
  setRoles: (roles?: string[]): UsersAction => ({
    payload: { roles },
    type: UsersActionType.SET_ROLES,
  }),
  setLoading: (is_loading: boolean): UsersAction => ({
    payload: { is_loading },
    type: UsersActionType.SET_LOADING,
  }),

  inviteUserById: (user_ids: string[]): UsersAction => ({
    payload: { user_ids },
    type: UsersActionType.SET_INVITE_USER_BY_ID,
  }),
  setError: (error: Error): UsersAction => ({
    payload: { error },
    type: UsersActionType.SET_ERROR,
  }),


  // users options.
  fetchUserOptions: (search?: string, min_role_level?: number, max_role_level?: number): UsersAction=> ({
    payload: { search, min_role_level, max_role_level },
    type: UsersActionType.FETCH_USER_OPTIONS,
  }),
  setUserOptionsLoading: (is_user_options_loading: boolean): UsersAction=> ({
    payload: { is_user_options_loading },
    type: UsersActionType.SET_USER_OPTIONS_LOADING,
  }),
  setUserOptions: (user_options: UserOptionsContent): UsersAction=> ({
    payload: { user_options },
    type: UsersActionType.SET_USER_OPTIONS,
  }),
  setUserOptionsSelectionId: (user_options_selection_id: string): UsersAction=> ({
    payload: { user_options_selection_id },
    type: UsersActionType.SET_USER_OPTIONS_SELECTION_ID,
  }),
  setMinRoleLevel: (min_role_level?: number): UsersAction=> ({
    payload: { min_role_level },
    type: UsersActionType.SET_MIN_ROLE_LEVEL,
  }),
  setMaxRoleLevel: (max_role_level?: number): UsersAction=> ({
    payload: { max_role_level },
    type: UsersActionType.SET_MAX_ROLE_LEVEL,
  }),


  // edit user.
  patchUser: (user_id: string, user: UserBodyRequestSchema): UsersAction => ({
    payload: { user_id, user },
    type: UsersActionType.UPDATE_USER,
  }),
  setUserUpdateLoading: (is_user_update_loading: boolean): UsersAction => ({
    payload: { is_user_update_loading },
    type: UsersActionType.SET_USER_UPDATE_LOADING,
  }),
  setUserContentLoading: (is_user_content_loading: boolean): UsersAction => ({
    payload: { is_user_content_loading },
    type: UsersActionType.SET_USER_CONTENT_LOADING,
  }),
  setUserUpdateSuccess: (is_user_update_success: boolean | undefined): UsersAction => ({
    payload: { is_user_update_success },
    type: UsersActionType.SET_USER_UPDATE_SUCCESS,
  }),

  // add user.
  postUser: (user: UserBodyRequestSchema): UsersAction => ({
    payload: { user },
    type: UsersActionType.CREATE_USER,
  }),

  setUserCreateLoading: (is_user_create_loading: boolean): UsersAction => ({
    payload: { is_user_create_loading },
    type: UsersActionType.SET_USER_CREATE_LOADING,
  }),

  setUserCreateSuccess: (is_user_create_success: boolean | undefined): UsersAction => ({
    payload: { is_user_create_success },
    type: UsersActionType.SET_USER_CREATE_SUCCESS,
  }),

  // delete user.
  deleteUser: (user_id: string): UsersAction => ({
    payload: { user_id },
    type: UsersActionType.DELETE_USER,
  }),
  setUserDeleteLoading: (is_user_delete_loading: boolean): UsersAction => ({
    payload: { is_user_delete_loading },
    type: UsersActionType.SET_USER_DELETE_LOADING,
  }),
  setUserDeleteSuccess: (is_user_delete_success: boolean | undefined): UsersAction => ({
    payload: { is_user_delete_success },
    type: UsersActionType.SET_USER_DELETE_SUCCESS,
  }),

  // role types.
  fetchRoleTypes: (): UsersAction => ({
    type: UsersActionType.FETCH_ROLES_TYPES,
  }),

  setRoleTypes: (role_types: SiteRoleResponse): UsersAction => ({
    payload: { role_types },
    type: UsersActionType.SET_ROLE_TYPES,
  }),

  setRoleTypesLoading: (is_role_types_loading: boolean): UsersAction => ({
    payload: { is_role_types_loading },
    type: UsersActionType.SET_ROLE_TYPES_LOADING,
  }),

  // bulk insert.
  bulkInsert: (file: any, company_id?: string): UsersAction => ({
    payload: { file, company_id },
    type: UsersActionType.BULK_INSERT,
  }),


  setBulkInsertLoading: (is_bulk_insert_loading: boolean): UsersAction => ({
    payload: { is_bulk_insert_loading },
    type: UsersActionType.SET_BULK_INSERT_LOADING,
  }),

  setBulkInsertResult: (bulk_insert_result: any): UsersAction => ({
    payload: { bulk_insert_result },
    type: UsersActionType.SET_BULK_INSERT_RESULT,
  }),

  clearState: (): UsersAction => ({
    type: UsersActionType.CLEAR,
  }),

  //role options
  fetchRoleOptions: (): UsersAction => ({
    type: UsersActionType.FETCH_ROLE_OPTIONS,
  }),
  setRoleOptions: (role_options: any): UsersAction => ({
    payload: { role_options },
    type: UsersActionType.SET_ROLE_OPTIONS,
  }),
  setRoleOptionsLoading: (is_role_options_loading: boolean): UsersAction => ({
    payload: { is_role_options_loading },
    type: UsersActionType.SET_ROLE_OPTIONS_LOADING,
  }),

};

export default actions;
