import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// ca
import {
  useSnackbar,
  alpha,
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  MenuPopover,
  IconButtonAnimate,
  InputStyle,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Checkbox,
  debounce,
} from 'ca-react-component-lib';

// routes
import { PATH_AUTH } from 'src/routes/paths';

// hooks
import useAuth from 'src/hooks/useAuth';
import useProfile from 'src/hooks/useProfile';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useCustomerSuccessAccess, useSiteSearchQuery } from 'src/hooks';

// components
import { MyAvatar } from 'src/components';

// constants
import { MENU_OPTIONS } from './constants';

// storage
import { clearTodoCompany } from 'src/storage/TodoCompany';
import { ROLE } from 'src/@types/user';

export default function ProfilePopover() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { logout } = useAuth();
  const { setSearchQueryClear } = useSiteSearchQuery();
  const { profile, displayName, role } = useProfile();
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const isMountedRef = useIsMountedRef();

  const { customerSuccessCompanies, setCustomerSuccessCompany, fetchCustomerSuccessCompanies, setCustomerSuccessCompaniesSearch, customerSuccessCompaniesSearch } =
    useCustomerSuccessAccess();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      setSearchQueryClear();
      clearTodoCompany();

      await logout();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleCompanySwitch = (companyId?: string) => {
    if (!companyId || companyId === profile.user?.current_company?.id) {
      return;
    }

    setCustomerSuccessCompany(companyId);
  };

  const onCompaniesSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;

      setCustomerSuccessCompaniesSearch(value);

      debounce(() => {
        if (value) {
          fetchCustomerSuccessCompanies(value);
        } else {
          fetchCustomerSuccessCompanies(undefined);
        }
      }, 300);
    },
    [fetchCustomerSuccessCompanies, setCustomerSuccessCompaniesSearch]
  );

  const formatRoleName = (roleName) => roleName
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');


  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.5),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
          width: '240px !important',
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5 }} noWrap>
            {formatRoleName(role)}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {profile.user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.filter((option) => option.linkTo).map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => {
                option.target ? window.open(option.linkTo, '_target') : navigate(option.linkTo);
                handleClose();
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {customerSuccessCompanies?.companies?.length > 0 && role === ROLE.CUSTOMER_SUCCESS && (
          <>
            <Stack sx={{ p: 1 }}>
              <Typography
                sx={{
                  mx: 1.5,
                  my: 1,
                  color: 'text.secondary',
                }}
                variant="caption"
              >
                Companies
              </Typography>
              <InputStyle
                fullWidth
                value={customerSuccessCompaniesSearch}
                onChange={onCompaniesSearchChange}
                placeholder="Search company..."
              />
              <Table sx={{ mt: 2 }}>
                <TableBody
                  sx={{
                    display: 'block',
                    maxHeight: 300,
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                      height: '8px', // Height of the scrollbar (for horizontal scrollbar)
                    },
                    '&::-webkit-scrollbar-track': {
                      borderRadius: '10px',
                      background: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      borderRadius: '10px',
                      background: '#888',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      background: '#555',
                    },
                    // Override the global scrollbar color
                    scrollbarColor: 'auto !important',
                  }}
                >
                  {customerSuccessCompanies?.companies?.map((item, key) => (
                    <TableRow key={key}>
                      <TableCell align="left">
                        <Checkbox
                          checked={item.is_current === true}
                          onClick={() => {
                            if (!item.company_id) return;
                            return handleCompanySwitch(item.company_id);
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">{item.company_name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Stack>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </>
        )}

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
