import { CompanyContent, CompanyPagination, OwnershipTypeContent, CompanyOptionsContent, CompanyRequestObj } from 'src/@types/company';
import { CompanyAction, CompanyActionType } from './types';


const actions = {
  fetchAllCompanies: (): CompanyAction => ({
    type: CompanyActionType.FETCH_ALL_COMPANIES,
  }),
  setCompanies: (company_pagination: CompanyPagination): CompanyAction => ({
    payload: { company_pagination },
    type: CompanyActionType.SET_COMPANIES,
  }),
  fetchCompanyContent: (company_id: string): CompanyAction => ({
    payload: { company_id },
    type: CompanyActionType.FETCH_COMPANY_CONTENT,
  }),
  setCompanyContent: (company_content: CompanyContent): CompanyAction => ({
    payload: { company_content },
    type: CompanyActionType.SET_COMPANY_CONTENT,
  }),
  setLimit: (limit: number): CompanyAction => ({
    payload: { limit },
    type: CompanyActionType.SET_LIMIT,
  }),
  setCursor: (cursor: string): CompanyAction => ({
    payload: { cursor },
    type: CompanyActionType.SET_CURSOR,
  }),
  setOrderBy: (order_by: 'name' | 'short_name' | 'abbreviation' | 'users_count'): CompanyAction => ({
    payload: { order_by },
    type: CompanyActionType.SET_ORDERBY,
  }),
  setOrderByDirection: (order_by_direction: 'asc' | 'desc'): CompanyAction => ({
    payload: { order_by_direction },
    type: CompanyActionType.SET_ORDERBY_DIRECTION,
  }),
  setSearch: (search: string): CompanyAction => ({
    payload: { search },
    type: CompanyActionType.SET_SEARCH,
  }),
  setSearchField: (search_field: 'name' | 'short_name' | 'abbreviation' | 'users_count'): CompanyAction => ({
    payload: { search_field },
    type: CompanyActionType.SET_SEARCH_FIELD,
  }),
  setIsVendor: (is_vendor: boolean): CompanyAction => ({
    payload: { is_vendor },
    type: CompanyActionType.SET_IS_VENDOR,
  }),
  setIsTowerOwner: (is_tower_owner: boolean): CompanyAction => ({
    payload: { is_tower_owner },
    type: CompanyActionType.SET_IS_TOWER_OWNER,
  }),
  setLoading: (is_loading: boolean): CompanyAction => ({
    payload: { is_loading },
    type: CompanyActionType.SET_LOADING,
  }),
  setCompanyContentLoading: (company_content_loading: boolean): CompanyAction => ({
    payload: { company_content_loading },
    type: CompanyActionType.SET_COMPANY_CONTENT_LOADING,
  }),
  setCompanyCreateLoading: (company_create_loading: boolean): CompanyAction => ({
    payload: { company_create_loading },
    type: CompanyActionType.SET_COMPANY_CREATE_LOADING,
  }),
  setCompanyUpdateLoading: (company_update_loading: boolean): CompanyAction => ({
    payload: { company_update_loading },
    type: CompanyActionType.SET_COMPANY_UPDATE_LOADING,
  }),
  setCompanyRequestObj: (company_request_obj: CompanyRequestObj): CompanyAction => ({
    payload: { company_request_obj },
    type: CompanyActionType.SET_COMPANY_REQUEST_OBJ,
  }),
  postCompany: (company_request_obj: CompanyRequestObj): CompanyAction => ({
    payload: { company_request_obj },
    type: CompanyActionType.CREATE_COMPANY,
  }),
  patchCompany: (company_id: string, company_request_obj: CompanyRequestObj): CompanyAction => ({
    payload: { company_id, company_request_obj },
    type: CompanyActionType.UPDATE_COMPANY,
  }),
  setError: (error: Error): CompanyAction => ({
    payload: { error },
    type: CompanyActionType.SET_ERROR,
  }),
  setCompanyCreateSuccess: (is_company_create_success: boolean): CompanyAction => ({
    payload: { is_company_create_success },
    type: CompanyActionType.SET_COMPANY_CREATE_SUCCESS,
  }),
  setCompanyUpdateSuccess: (is_company_update_success: boolean): CompanyAction => ({
    payload: { is_company_update_success },
    type: CompanyActionType.SET_COMPANY_UPDATE_SUCCESS,
  }),

  //delete company
  deleteCompany: (company_id: string): CompanyAction => ({
    payload: { company_id },
    type: CompanyActionType.DELETE_COMPANY,
  }),

  setCompanyDeleteSuccess: (is_company_delete_success: boolean): CompanyAction => ({
    payload: { is_company_delete_success },
    type: CompanyActionType.SET_COMPANY_DELETE_SUCCESS,
  }),

  setCompanyDeleteLoading: (is_company_delete_loading: boolean): CompanyAction => ({
    payload: { is_company_delete_loading },
    type: CompanyActionType.SET_COMPANY_DELETE_LOADING,
  }),

  deleteCompanyAssetGroupPermission: (company_id: string): CompanyAction => ({
    payload: { company_id },
    type: CompanyActionType.DELETE_COMPANY_ASSET_GROUP_PERMISSION,
  }),

  deleteCompanyCustomer: (company_id: string): CompanyAction => ({
    payload: { company_id },
    type: CompanyActionType.DELETE_COMPANY_CUSTOMER,
  }),

  deleteCompanyGuestAccess: (company_id: string): CompanyAction => ({
    payload: { company_id },
    type: CompanyActionType.DELETE_COMPANY_GUEST_ACCESS,
  }),

  // companies options.
  fetchCompanyOptions: (search?: string, is_vendor?: boolean, is_tower_owner?: boolean): CompanyAction => ({
    payload: { search, is_vendor, is_tower_owner },
    type: CompanyActionType.FETCH_COMPANY_OPTIONS,
  }),
  setCompanyOptionsLoading: (is_company_options_loading: boolean): CompanyAction => ({
    payload: { is_company_options_loading },
    type: CompanyActionType.SET_COMPANY_OPTIONS_LOADING,
  }),
  setCompanyOptions: (company_options: CompanyOptionsContent): CompanyAction => ({
    payload: { company_options },
    type: CompanyActionType.SET_COMPANY_OPTIONS,
  }),
  setCompanySelectionId: (company_selection_id: string): CompanyAction => ({
    payload: { company_selection_id },
    type: CompanyActionType.SET_COMPANY_SELECTION_ID,
  }),

  // Ownership types.
  fetchOwnershipTypes: (): CompanyAction => ({
    type: CompanyActionType.FETCH_OWNERSHIP_TYPES,
  }),

  setOwnershipTypesLoading: (is_ownership_types_loading: boolean): CompanyAction => ({
    payload: { is_ownership_types_loading },
    type: CompanyActionType.SET_OWNERSHIP_TYPES_LOADING,
  }),

  setOwnershipTypes: (ownership_types: OwnershipTypeContent): CompanyAction => ({
    payload: { ownership_types },
    type: CompanyActionType.SET_OWNERSHIP_TYPES,
  }),

  clearState: (): CompanyAction => ({
    type: CompanyActionType.CLEAR,
  }),
};

export default actions;
