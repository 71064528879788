import { put, takeLatest, call, select, delay } from 'redux-saga/effects';
import selectors from './selectors';
import actions from './actions';
import * as CustomerSuccessAccessServices from 'src/servicesV2/customerSuccessAccess';
import { CustomerSuccessAccessAction, CustomerSuccessAccessActionType } from './types';
import { setAuthRefreshToken, setAuthToken } from '../../storage/AuthToken';

import toastActions from 'src/store/Toast/actions';
import { TOAST_SEVERITY } from 'src/@types/toast';

export function* fetchAllCustomerSuccessAccess() {
  try {
    //fetch list
    const limit: number = yield select(selectors.getLimit);
    const cursor: string = yield select(selectors.getCursor);
    const search: string = yield select(selectors.getSearch);
    const searchField: string = yield select(selectors.getSearchField);
    const order_by: string = yield select(selectors.getOrderBy);
    const order_by_direction: string = yield select(selectors.getOrderByDirection);
    const company_id: string | undefined = yield select(selectors.getCompanyId);
    const user_id: string | undefined = yield select(selectors.getUserId);

    yield put(actions.setLoading(true));

    const response = yield call(
      CustomerSuccessAccessServices.fetchAllCustomerSuccessAccess,
      limit,
      cursor,
      search,
      searchField,
      order_by_direction,
      order_by,
      company_id,
      user_id
    );
    yield put(actions.setAllCustomerSuccessAccess(response));
  } catch (e: any) {
    yield put(actions.setError(e));
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* postCustomerSuccessAccess({ payload }: CustomerSuccessAccessAction) {
  try {
    yield put(actions.setPostCustomerSuccessAccessLoading(true));
    const response = yield call(
      CustomerSuccessAccessServices.postCustomerSuccessAccess,
      payload?.customer_success_access
    );

    if (
      Array.isArray(response.customer_success_accesses) &&
      response.customer_success_accesses.length > 0
    ) {
      const firstRow = response.customer_success_accesses[0];
      if (firstRow.id) {
        yield put(toastActions.setToast('Create Success!'));
        yield put(actions.setPostCustomerSuccessAccessSuccess(true));
      } else {
        yield put(
          toastActions.setToast(
            'The rows had issues being created.',
            TOAST_SEVERITY.ERROR
            // { autoHideDuration: 10000 }
          )
        );
        yield put(actions.setPostCustomerSuccessAccessSuccess(false));
      }
    } else {
      yield put(
        toastActions.setToast(
          'No rows found.',
          TOAST_SEVERITY.ERROR
          // { autoHideDuration: 10000 }
        )
      );
      yield put(actions.setPostCustomerSuccessAccessSuccess(false));
    }
  } catch (e) {
    console.error(e);
    yield put(actions.setPostCustomerSuccessAccessSuccess(false));
  } finally {
    yield put(actions.setPostCustomerSuccessAccessLoading(false));
    yield put(actions.setPostCustomerSuccessAccessSuccess(false));
  }
}

export function* deleteCustomerSuccessAccess({ payload }: CustomerSuccessAccessAction) {
  if (!payload || !payload.customer_success_access) {
    return;
  }
  try {
    const { customer_success_access } = payload;
    yield put(actions.setDeleteCustomerSuccessAccessLoading(true));
    const response = yield call(
      CustomerSuccessAccessServices.deleteCompanySuccessAccess,
      customer_success_access
    );

    if (
      Array.isArray(response.customer_success_accesses) &&
      response.customer_success_accesses.length > 0
    ) {
      const firstRow = response.customer_success_accesses[0];
      if (firstRow.id) {
        yield put(toastActions.setToast('Delete Success!'));
        yield put(actions.setPostCustomerSuccessAccessSuccess(true));
      } else {
        yield put(
          toastActions.setToast(
            'The rows had issues being deleted.',
            TOAST_SEVERITY.ERROR
            // { autoHideDuration: 10000 }
          )
        );
        yield put(actions.setPostCustomerSuccessAccessSuccess(false));
      }
    } else {
      yield put(
        toastActions.setToast(
          'No rows found.',
          TOAST_SEVERITY.ERROR
          // { autoHideDuration: 10000 }
        )
      );
      yield put(actions.setPostCustomerSuccessAccessSuccess(false));
    }

    yield put(actions.setDeleteCustomerSuccessAccessSuccess(true));
  } catch (e) {
    yield put(actions.setDeleteCustomerSuccessAccessSuccess(false));
  } finally {
    yield put(actions.setDeleteCustomerSuccessAccessLoading(false));
    yield put(actions.setDeleteCustomerSuccessAccessSuccess(false));
  }
}

export function* fetchCustomerSuccessCompanies({ payload }: CustomerSuccessAccessAction) {
  let search: string = yield select(selectors.getCustomerSuccessAccessCompaniesSearch);

  yield put(actions.setCustomerSuccessCompaniesLoading(true));


  if (payload) {
    const { search: newSearch } = payload;
    if (newSearch) {
      search = newSearch;
    }
  }

  try {
    const response = yield call(
      CustomerSuccessAccessServices.fetchCustomerSuccessCompanies,
      search
    );

    yield put(actions.setCustomerSuccessCompanies(response));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(actions.setCustomerSuccessCompaniesLoading(false));
  }
}

export function* setCustomerSuccessCompany({ payload }: CustomerSuccessAccessAction) {
  if (!payload || !payload.company_id) {
    return;
  }

  try {
    yield put(actions.setCustomerSuccessSwitchLoading(true));
    const companySwitchResponse = yield call(
      CustomerSuccessAccessServices.setCustomerSuccessCompany,
      payload.company_id
    );

    if (!companySwitchResponse?.auth_data) {
      console.error(companySwitchResponse.errors);
      throw new Error('Failed to switch company.');
    }

    setAuthRefreshToken(companySwitchResponse.auth_data.refresh_token);
    setAuthToken(companySwitchResponse.auth_data.access_token);
  } catch ({ message }) {
    console.error(message);
  } finally {
    yield delay(1000);
    window.location.reload();
    yield put(actions.setCustomerSuccessSwitchLoading(false));
  }
}

export function* fetchCustomerSuccessCompanyOptions({ payload }: CustomerSuccessAccessAction) {
  let search: string = yield select(selectors.getCustomerSuccessAccessCompanyOptionsSearch);
  let user_id: string = yield select(selectors.getUserId);

  if (payload) {
    const { user_id: newUserId, search: newSearch } = payload;
  
    if (newUserId !== undefined) {
      user_id = newUserId;
    }
    if (newSearch !== undefined) {
      search = newSearch;
    }
  }

  yield put(actions.setCustomerSuccessAccessCompanyOptionsLoading(true));
  try {
    const response = yield call(
      CustomerSuccessAccessServices.fetchCustomerSuccessAccessCompanyOptions,
      search,
      user_id
    );
    yield put(actions.setCustomerSuccessAccessCompanyOptions(response));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(actions.setCustomerSuccessAccessCompanyOptionsLoading(false));
  }
}

export function* fetchCustomerSuccessAccessUserOptions({ payload }: CustomerSuccessAccessAction) {
  let search: string = yield select(selectors.getCustomerSuccessAccessUserOptionsSearch);
  let company_id: string = yield select(selectors.getCompanyId);

  if (payload) {
    const { company_id: newCompanyId, search: newSearch } = payload;
  
    if (newCompanyId !== undefined) {
      company_id = newCompanyId;
    }
    if (newSearch !== undefined) {
      search = newSearch;
    }
  }

  yield put(actions.setCustomerSuccessAccessUserOptionsLoading(true));
  try {
    const response = yield call(
      CustomerSuccessAccessServices.fetchCustomerSuccessAccessUserOptions,
      search,
      company_id
    );
    yield put(actions.setCustomerSuccessAccessUserOptions(response));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(actions.setCustomerSuccessAccessUserOptionsLoading(false));
  }
}

const sagas: any[] = [
  takeLatest(
    CustomerSuccessAccessActionType.FETCH_ALL_CUSTOMER_SUCCESS_ACCESS,
    fetchAllCustomerSuccessAccess
  ),
  takeLatest(
    CustomerSuccessAccessActionType.POST_CUSTOMER_SUCCESS_ACCESS,
    postCustomerSuccessAccess
  ),
  takeLatest(
    CustomerSuccessAccessActionType.DELETE_CUSTOMER_SUCCESS_ACCESS,
    deleteCustomerSuccessAccess
  ),
  takeLatest(
    CustomerSuccessAccessActionType.FETCH_CUSTOMER_SUCCESS_COMPANIES,
    fetchCustomerSuccessCompanies
  ),
  takeLatest(
    CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_COMPANY,
    setCustomerSuccessCompany
  ),
  takeLatest(
    CustomerSuccessAccessActionType.FETCH_CUSTOMER_SUCCESS_ACCESS_COMPANY_OPTIONS,
    fetchCustomerSuccessCompanyOptions
  ),
  takeLatest(
    CustomerSuccessAccessActionType.FETCH_CUSTOMER_SUCCESS_ACCESS_USER_OPTIONS,
    fetchCustomerSuccessAccessUserOptions
  ),
];

export default sagas;
