const apiUrl = process.env.REACT_APP_STAFF_V2_API_ENDPOINT;

const endpoints = {
  site: `${apiUrl}/api/site/`,
  structure_type: `${apiUrl}/api/site/structure-type/`,
  site_user_role: `${apiUrl}/api/site-user-role/`,

  assessment: `${apiUrl}/api/assessment/`,
  azureImport: `${apiUrl}/api/trigger-download-files-from-azure/`,
  azureExport: `${apiUrl}/api/trigger-export-files-to-azure/`,
  imageImporterStatus: `${apiUrl}/api/image-importer-status/`,
  imageExporterStatus: `${apiUrl}/api/image-exporter-status/`,
  modelProcessingQueues: `${apiUrl}/api/model-3d-processing/queue/`,
  submit_queue_for_processing: `${apiUrl}/api/ImageProcessing/QueueSite`,

  //bulk site upload
  site_bulk_insert: `${apiUrl}/api/site/bulk-create-excel-or-csv/`,
  site_bulk_template: `${apiUrl}/api/site/bulk-create-template/`,
};

export default endpoints;
