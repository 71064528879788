// Import the api object from the api module
import { ProcessPipelineResponseSchema } from './types';
import api from './api';

/**
 * Fetches the content of a specific process pipeline by its ID, parsing it into a structured object.
 *
 * @param action - The ID of the process pipeline to fetch.
 * @returns Promise resolving to the parsed process pipeline content.
 */

export const fetchTodoWaitingForImages = async (company_id?: string) => {
  try {
    const response: ProcessPipelineResponseSchema = await api.fetchTodoWaitingForImages(company_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchTodoQueue = async (company_id?: string) => {
  try {
    const response: ProcessPipelineResponseSchema = await api.fetchTodoQueueInfo(company_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchTodoProcessing = async (company_id?: string) => {
  try {
    const response: ProcessPipelineResponseSchema = await api.fetchTodoProcessingInfo(company_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};


export const fetchTodoPerformQC = async (company_id?: string) => {
  try {
    const response: ProcessPipelineResponseSchema = await api.fetchTodoPerformQC(company_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchTodoCompleted = async (company_id?: string) => {
  try {
    const response: ProcessPipelineResponseSchema = await api.fetchTodoCompletedInfo(company_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};


export const fetchTodoRefly = async (company_id?: string) => {
  try {
    const response: ProcessPipelineResponseSchema = await api.fetchTodoReflyInfo(company_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchTodoMeasurements = async (company_id?: string) => {
  try {
    const response = await api.fetchTodoMeasurementsInfo(company_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchTodoReprocessing = async (company_id?: string) => {
  try {
    const response: ProcessPipelineResponseSchema = await api.fetchTodoReprocessingInfo(company_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const editSiteAssessmentStaging = async (
  assessment_id: string,
  payload: any
): Promise<any> => {
  try {
    const response = await api.editSiteAssessmentStaging(assessment_id, payload);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};