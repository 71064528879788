import { takeLatest, put, call } from 'redux-saga/effects';

// service
import { fetchAllSites, fetchStructureTypeOptions } from 'src/servicesV2/site';
import { fetchCompanyOptions } from 'src/servicesV2/company';

// reducer
import { initialState } from './reducer';

// types
import { KEYS } from 'src/@types/root';
import { SiteSearchQueryAction, SiteSearchQueryActionType } from './types';
import { SitePagination, StructureTypeContent } from 'src/@types/site';

// actions
import actions from './actions';

// utils
import { setData, clearData } from 'ca-react-util-lib';
import { CompanyOptionsContent } from 'src/@types/company';

export function* setQuickSearchQuery({ payload }: SiteSearchQueryAction) {
  yield put(actions.setQuickSearchLoading(true));

  try {
    const sitePageSearchQueryResult: SitePagination = yield call(
      fetchAllSites,
      payload!.quickSearchQuery
    );

    yield put(actions.setQuickSearchQueryResult(sitePageSearchQueryResult));
  } catch (e) {
    console.log(e);
  } finally {
    yield put(actions.setQuickSearchLoading(false));
  }
}

export function* setSearchQuery({ payload }: SiteSearchQueryAction) {
  yield put(actions.setSearchLoading(true));
  try {
    const sitePageSearchQueryResult: SitePagination = yield call(
      fetchAllSites,
      payload!.searchQuery
    );

    setData(KEYS.SEARCH_QUERY, JSON.stringify(payload!.searchQuery));
    yield put(actions.setSearchQueryResult(sitePageSearchQueryResult));
  } catch (e) {
    console.log(e);
  } finally {
    yield put(actions.setSearchLoading(false));
  }
}

export function* setSearchQueryClear() {
  // after reducer updated, search sites with initial params.
  yield put(actions.setSearchQuery(initialState.searchQuery));
  clearData(KEYS.SEARCH_QUERY);
}

export function* getSearchQuerySelectOptions() {
  try {
    yield put(actions.setSelectOptionsLoading(true));
    const structureTypesSelectOptions: StructureTypeContent = yield call(fetchStructureTypeOptions);
    const towerOwnersSelectOptions: CompanyOptionsContent = yield call(fetchCompanyOptions, '', false, true);
    const vendorsSelectOptions: CompanyOptionsContent = yield call(fetchCompanyOptions, '', true, false);

    yield put(
      actions.setSearchQuerySelectOptions(
        structureTypesSelectOptions,
        towerOwnersSelectOptions,
        vendorsSelectOptions
      )
    );
  } catch (e) {
    console.error(e);
  } finally {
    yield put(actions.setSelectOptionsLoading(false));
  }
}

const sagas = [
  takeLatest(SiteSearchQueryActionType.SET_QUICK_SEARCH_QUERY, setQuickSearchQuery),
  takeLatest(SiteSearchQueryActionType.SET_SEARCH_QUERY, setSearchQuery),
  takeLatest(SiteSearchQueryActionType.SET_SEARCH_QUERY_CLEAR, setSearchQueryClear),
  takeLatest(
    SiteSearchQueryActionType.GET_SEARCH_QUERY_SELECT_OPTIONS,
    getSearchQuerySelectOptions
  ),
];

export default sagas;
