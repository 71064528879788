// hooks
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import siteActions from 'src/store/Site/actions';

// selectors
import siteSelectors from 'src/store/Site/selectors';

// types
import { SiteImage } from 'src/@types/site';
import { PhotoTiePoints } from 'src/servicesV2/site/types';
import { ProcessingData } from 'src/components/QueueMap/types';

const useSite = () => {
  const site = useSelector(siteSelectors.getSite);
  const loading = useSelector(siteSelectors.isLoading);
  const isPhotosLoading = useSelector(siteSelectors.isPhotosLoading);
  const currentSiteAssessmentPhotosAssetProfile = useSelector(
    siteSelectors.getCurrentSiteAssessmentPhotosAssetProfile
  );
  const error = useSelector(siteSelectors.getError);
  const currentSiteAssessmentId = useSelector(siteSelectors.getCurrentSiteAssessmentId);
  const assetProfilePhoto = useSelector(siteSelectors.getAssetProfilePhoto);
  const currentSiteAssessmentPhotos = useSelector(siteSelectors.getCurrentSiteAssessmentPhotos);
  const currentSiteAssessmentFolders = useSelector(siteSelectors.getCurrentSiteAssessmentFolders);
  const isAddSiteSuccess = useSelector(siteSelectors.getIsAddSiteSuccess);
  const isFetchSiteSuccess = useSelector(siteSelectors.getIsFetchSiteSuccess);

  const currentSiteAssessment = useSelector(siteSelectors.getCurrentSiteAssessment);
  const dailyForecasts = useSelector(siteSelectors.getDailyForecasts);

  const clientSiteIdentifier = useSelector(siteSelectors.getClientSiteIdentifier);

  const isSubmitQueueForProcessingLoading = useSelector(siteSelectors.getIsSubmitQueueForProcessingLoading);
  const isSubmitQueueForProcessingSuccess = useSelector(siteSelectors.getIsSubmitQueueForProcessingSuccess);

  //bulk insert
  const isBulkInsertLoading = useSelector(siteSelectors.getIsBulkInsertLoading);
  const bulkInsertResult = useSelector(siteSelectors.getBulkInsertResult);


  const isStructureSelectOptionsLoading = useSelector(
    siteSelectors.getIsStructureSelectOptionsLoading
  );

  const photoTab = useSelector(siteSelectors.getPhotoTab);
  const photoLimit = useSelector(siteSelectors.getPhotoLimit);
  const photoOffset = useSelector(siteSelectors.getPhotoOffset);

  const jobProgress = useSelector(siteSelectors.getJobProgress);

  const dispatch = useDispatch();

  const fetchSite = useCallback(
    (siteId: string, assessmentId?: string) => {
      dispatch(siteActions.fetchSite(siteId, assessmentId));
    },
    [dispatch]
  );

  const setCurrentSiteAssessmentId = useCallback(
    (siteAssessmentId: string) => {
      dispatch(siteActions.setCurrentSiteAssessmentId(siteAssessmentId));
    },
    [dispatch]
  );


  const fetchSiteAssessmentStaging = useCallback(
    (assessmentId: string) => {
      dispatch(siteActions.fetchSiteAssessmentStaging(assessmentId));
    },
    [dispatch]
  );


  //structure select options
  const structureSelectOptions = useSelector(siteSelectors.getStructureSelectOptions);
  const fetchStructureSelectOptions = useCallback(() => {
    dispatch(siteActions.fetchStructureSelectOptions());
  }, [dispatch]);

  //add site
  const createSite = useCallback(
    (siteRoot: any) => {
      dispatch(siteActions.createSite(siteRoot));
    },
    [dispatch]
  );

  //bulk insert
  const bulkInsert = useCallback(
    (file: any, owner_id?: string) => {
      dispatch(siteActions.bulkInsert(file, owner_id));
    },
    [dispatch]
  );

  // edit site
  const editSite = useCallback(
    (slmId: string, siteRoot: any) => {
      dispatch(siteActions.editSite(slmId, siteRoot));
    },
    [dispatch]
  );

  const setCurrentSiteAssessmentPhotos = useCallback(
    (photos: SiteImage[]) => {
      dispatch(siteActions.setCurrentSiteAssessmentPhotos(photos));
    },
    [dispatch]
  );

  const setIsPhotosLoading = useCallback(
    (photosLoading: boolean) => {
      dispatch(siteActions.setPhotosLoading(photosLoading));
    },
    [dispatch]
  );

  const editSiteAssessmentStaging = useCallback(
    (assesmentId: string, payload: any) => {
      dispatch(siteActions.editSiteAssessmentStaging(assesmentId, payload));
    },
    [dispatch]
  );

  const editPhotoTiePoints = useCallback(
    (payload: PhotoTiePoints) => {
      dispatch(siteActions.editPhotoTiePoints(payload));
    },
    [dispatch]
  );

  const deletePhotoTiePoints = useCallback(
    (currentPhotoId: string, siteAssessmentId?: string) => {
      dispatch(siteActions.deletePhotoTiePoints(currentPhotoId, siteAssessmentId));
    },
    [dispatch]
  );

  const startAzureImport = useCallback(
    (url: string) => {
      dispatch(siteActions.startAzureImport(url));
    },
    [dispatch]
  );

  const startAzureExport = useCallback(
    (url: string) => {
      dispatch(siteActions.startAzureExport(url));
    },
    [dispatch]
  );

  const forceCompleteUpload = useCallback(() => dispatch(siteActions.forceCompleteUpload()), [dispatch]);

  const submitQueueForProcessing = useCallback(
    (processingData: ProcessingData, assessmentId: string, siteId?: string) => {
      dispatch(siteActions.submitQueueForProcessing(processingData, assessmentId, siteId));
    },
    [dispatch]
  );

  const setPhotoTab = useCallback((tabIndex: number) => {
    dispatch(siteActions.setPhotoTab(tabIndex));
  }, [dispatch]);

  const setJobProgress = useCallback((jobProgress) => {
    dispatch(siteActions.setJobProgress(jobProgress));
  }, [dispatch]);

  const setPhotoLimit = useCallback((photoLimit) => {
    dispatch(siteActions.setPhotoLimit(photoLimit));
  }, [dispatch]);

  const setPhotoOffset = useCallback((photoLimit) => {
    dispatch(siteActions.setPhotoOffset(photoLimit));
  }, [dispatch]);

  const deleteSiteAssessmentStaging = useCallback(
    (assesmentId: string) => {
      dispatch(siteActions.deleteSiteAssessmentStaging(assesmentId));
    },
    [dispatch]
  );

  const deleteSiteUser = useCallback(
    (site_user_id: any) => {
      dispatch(siteActions.deleteSiteUser(site_user_id));
    },
    [dispatch]
  );

  const clearState = useCallback(() => {
    dispatch(siteActions.clearState());
  }, [dispatch]);



  return {
    site,
    loading,
    isPhotosLoading,
    setIsPhotosLoading,
    error,
    currentSiteAssessmentId,
    currentSiteAssessment,
    setCurrentSiteAssessmentId,
    currentSiteAssessmentPhotos,
    setCurrentSiteAssessmentPhotos,
    currentSiteAssessmentFolders,
    dailyForecasts,
    fetchSite,
    isAddSiteSuccess,
    isFetchSiteSuccess,

    fetchSiteAssessmentStaging,
    clientSiteIdentifier,
    currentSiteAssessmentPhotosAssetProfile,
    assetProfilePhoto,

    isSubmitQueueForProcessingLoading,
    isSubmitQueueForProcessingSuccess,

    //structure select options
    structureSelectOptions,
    isStructureSelectOptionsLoading,
    fetchStructureSelectOptions,

    //add
    createSite,

    //bulk insert
    isBulkInsertLoading,
    bulkInsertResult,
    bulkInsert,

    //edit
    editSite,
    editPhotoTiePoints,
    deletePhotoTiePoints,
    submitQueueForProcessing,
    deleteSiteAssessmentStaging,
    deleteSiteUser,
    startAzureImport,
    startAzureExport,
    forceCompleteUpload,
    editSiteAssessmentStaging,

    photoTab,
    photoLimit,
    photoOffset,
    setPhotoTab,
    setPhotoLimit,
    setPhotoOffset,

    //upload job progress
    jobProgress,
    setJobProgress,

    clearState
  };
};

export default useSite;
